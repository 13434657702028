export const BLACK_COLOR = '#000000';
export const WHITE_COLOR = '#FFFFFF';
export const TORY_BLUE_COLOR = '#344090';
export const NAVY_BLUE_COLOR = '#1976D2';
export const PATTENS_BLUE_COLOR = '#D8DFE5';
export const MID_NIGHT_COLOR = '#233044';
export const ALICE_BLUE_COLOR = '#F7F9FC';
export const ALICE_BLUE_2_COLOR = '#F1F7FD';
export const NOBEL_COLOR = '#9E9E9E';
export const CHARCOAL_COLOR = '#424242';
export const DARK_CERULEAN_COLOR = '#134A84';
export const NIGHT_RIDER_COLOR = '#333333';
export const GAINSBORO_COLOR = '#E0E0E0';
export const GAINSBORO_2_COLOR = '#E3E3E3';
export const SNOW_COLOR = '#FAFAFA';
export const DODGER_BLUE_COLOR = '#007DFF';
export const DODGER_BLUE_2_COLOR = '#2196F3';
export const RED_ORANGE_COLOR = '#F44336';
export const FRUIT_SALAD_COLOR = '#4CAF50';
export const ORANGE_PEEL_COLOR = '#FF9800';
export const SILVER_COLOR = '#BDBDBD';
export const NEON_BLUE_COLOR = '#536DFE';
export const TRUE_BLUE = '#0367DF';
