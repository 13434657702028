import { useCallback } from 'react';

import { useApolloClient } from '@apollo/client';
import { useDisconnect } from '@gusdk/gu-wallet-connector';

export const useLogout = () => {
  const { disconnect } = useDisconnect();
  const client = useApolloClient();
  return useCallback(async () => {
    await client.clearStore();
    await disconnect();
  }, [client, disconnect]);
};
