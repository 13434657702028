import { CHAIN_NAMESPACES, CustomChainConfig } from '@web3auth/base';

export const CHAIN_CONFIG = {
  sandbox: {
    displayName: 'G.U. Sandbox Chain',
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x1869f',
    rpcTarget: 'https://sandbox1.japanopenchain.org:8545',
    blockExplorer: 'https://sandbox1.japanopenchain.org/',
    ticker: 'STH',
    tickerName: 'Sandbox Jther',
  } as CustomChainConfig,
  joc: {
    displayName: 'Japan Open Chain Mainnet',
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x51',
    rpcTarget: 'https://mainnet.japanopenchain.org:8545',
    blockExplorer: 'https://mainnet.japanopenchain.org/',
    ticker: 'JTH',
    tickerName: 'Jther',
  } as CustomChainConfig,
  mainnet: {
    displayName: 'Ethereum Mainnet',
    chainNamespace: CHAIN_NAMESPACES.EIP155,
    chainId: '0x1',
    rpcTarget: `https://mainnet.infura.io/v3/776218ac4734478c90191dde8cae483c`,
    blockExplorer: 'https://etherscan.io/',
    ticker: 'ETH',
    tickerName: 'Ethereum',
  } as CustomChainConfig,
} as const;

export type CHAIN_CONFIG_TYPE = keyof typeof CHAIN_CONFIG;
