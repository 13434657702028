import * as React from 'react'

import { Connector } from "../../core/connectors/base";
import { useClient } from "../context";
import { ConnectorAlreadyConnectedError } from '../../core/errors';

export function useConnect() {
  const client = useClient()

  const connect = React.useCallback(async <T extends Connector>(connector: T, ...args: Parameters<T['connect']>) => {
    const activeConnector = client.connector
    if (connector.name === activeConnector?.name)
      throw new ConnectorAlreadyConnectedError()

    try {
      client.setState({
        status: "connecting"
      })
      const data = await connector.connect(...args);

      client.setState({ data, connector, chains: connector?.chains, status: "connected" })
      client.setLatestConnector(connector.name)
    } catch (error) {
      client.setState({
        status: "disconnected",
        error: error
      })

      throw error
    }


  }, [client])

  return {
    connect,
    status: client.status,
    connectors: client.connectors,
    activeConnector: client.connector
  }
}