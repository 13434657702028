import { Eip1193Provider } from 'ethers'
import { EIP6963ProviderDetail } from './connectors/eip-6963'

export interface EIP6963AnnounceProviderEvent<TProvider = Eip1193Provider>
  extends CustomEvent<EIP6963ProviderDetail<TProvider>> {
  type: 'eip6963:announceProvider'
}

export type RequestProvidersParameters = (providerDetail: EIP6963ProviderDetail) => void
export type RequestProvidersReturnType = (() => void) | undefined

export type Listener = (
  providerDetails: readonly EIP6963ProviderDetail[],
  meta?:
    | {
        added?: readonly EIP6963ProviderDetail[] | undefined
        removed?: readonly EIP6963ProviderDetail[] | undefined
      }
    | undefined
) => void

export class MpidStore {
  listeners: Set<Listener> = new Set()
  providerDetails: readonly EIP6963ProviderDetail[] = []

  constructor() {
    this._requestProviders((providerDetail) => {
      if (this.providerDetails.some(({ info }) => info.uuid === providerDetail.info.uuid)) return

      this.providerDetails = [...this.providerDetails, providerDetail]
      this.listeners.forEach((listener) => listener(this.providerDetails, { added: [providerDetail] }))
    })
  }

  destroy() {
    this.providerDetails = []
    this._requestProviders((providerDetail) => {
      if (this.providerDetails.some(({ info }) => info.uuid === providerDetail.info.uuid)) return

      this.providerDetails = [...this.providerDetails, providerDetail]
    })
  }

  subscribe(listener: Listener) {
    this.listeners.add(listener)
    return () => this.listeners.delete(listener)
  }

  getProviders() {
    return this.providerDetails
  }

  private _requestProviders(listener: RequestProvidersParameters): RequestProvidersReturnType {
    if (typeof window === 'undefined') return
    const handler = (event: EIP6963AnnounceProviderEvent) => listener(event.detail)

    window.addEventListener('eip6963:announceProvider', handler)

    window.dispatchEvent(new CustomEvent('eip6963:requestProvider'))

    return () => window.removeEventListener('eip6963:announceProvider', handler)
  }
}
