import { Data, State, Store } from './store'
import { Chain, Provider } from './types'
import { Connector } from './connectors/base'
import { InjectedConnector } from './connectors/injected'
import { MpidStore } from './mipd-store'
import { EIP6963InjectedConnector } from './connectors/eip-6963'
import { defaultChains } from './constants/chains'

export type ClientConfig = {
  connectors?: Connector[]
  multiInjectedProviderDiscovery?: boolean
  multiInjectedProviderChains?: Chain[]
}

export class Client<TProvider extends Provider = Provider> {
  config: ClientConfig
  store: Store<TProvider>
  mipdStore?: MpidStore

  constructor({
    connectors = [new InjectedConnector()],
    multiInjectedProviderDiscovery = false,
    multiInjectedProviderChains = defaultChains
  }: ClientConfig = {}) {
    let _status: State<TProvider>['status'] = 'disconnected'
    if (typeof window !== 'undefined') {
      const latestUsedConnectorName = localStorage.getItem('guwc.connector')
      if (latestUsedConnectorName) {
        _status = 'reconnecting'
      }
    }

    let _connectors = connectors

    if (multiInjectedProviderDiscovery && typeof window !== 'undefined') {
      this.mipdStore = new MpidStore()
      const eipInjectedConnectors = this.mipdStore
        .getProviders()
        .map(
          (eip6963ProviderDetail) =>
            new EIP6963InjectedConnector({ chains: multiInjectedProviderChains, eip6963ProviderDetail })
        )
      _connectors = [..._connectors, ...eipInjectedConnectors]
    }

    this.store = new Store({
      initialState: { connectors: _connectors, status: _status }
    })

    this.config = {
      connectors: _connectors
    }

    // Subscribe new connectors
    this.mipdStore?.subscribe((providerDetails) => {
      const currentConnectorIds = new Map()
      for (const connector of this.connectors) {
        currentConnectorIds.set(connector.name, true)
      }

      const newConnectors: Connector[] = []
      for (const eip6963ProviderDetail of providerDetails) {
        const connector = new EIP6963InjectedConnector({ chains: multiInjectedProviderChains, eip6963ProviderDetail })

        if (currentConnectorIds.has(connector.name)) continue
        newConnectors.push(connector)
      }

      this.setState({ connectors: [...this.connectors, ...newConnectors] })
    })

    this.onConnectorUpdate = this.onConnectorUpdate.bind(this)
    this.onConnectorError = this.onConnectorError.bind(this)
    this.onConnectorDisconnect = this.onConnectorDisconnect.bind(this)
  }

  get chains() {
    return this.store.getState().chains
  }
  get connectors() {
    return this.store.getState().connectors
  }
  get connector() {
    return this.store.getState().connector
  }
  get data() {
    return this.store.getState().data
  }
  get error() {
    return this.store.getState().error
  }
  get status() {
    return this.store.getState().status
  }

  get latestConnector() {
    return localStorage.getItem('guwc.connector')
  }

  setState(updater: Partial<State<TProvider>>) {
    if (updater.connector) {
      const prevConnector = this.connector
      this.addEffects(updater.connector, prevConnector)
    }
    this.store.setState(updater)
  }

  clearState() {
    // remove connector event when disconnected
    this.addEffects(undefined, this.connector)
    this.store.clearState()
  }

  setLatestConnector(name: string) {
    localStorage.setItem('guwc.connector', name)
  }

  clearLatestConnector() {
    localStorage.removeItem('guwc.connector')
  }

  async destroy() {
    if (this.connector) await this.connector.disconnect?.()
    this.clearState()
  }

  async autoConnect() {
    if (!this.connectors.length) return

    let connected = false
    for (const connector of this.connectors) {
      if (!connector.ready || !connector.isAuthorized) continue
      const isAuthorized = await connector.isAuthorized()
      if (!isAuthorized) continue

      const data = await connector.connect()

      this.setState({
        connector,
        chains: connector.chains,
        data,
        status: 'connected'
      })
      connected = true
      break
    }

    if (!connected)
      this.setState({
        data: undefined,
        status: 'disconnected'
      })

    return this.data
  }

  async reconnect() {
    if (!this.connectors.length) return

    let connected = false
    const latestUsedConnectorName = localStorage.getItem('guwc.connector')
    const latestUsedConnector = this.connectors.find((connector) => connector.name === latestUsedConnectorName)

    if (latestUsedConnector && latestUsedConnector.ready && latestUsedConnector.isAuthorized) {
      const isAuthorized = await latestUsedConnector.isAuthorized()
      if (isAuthorized) {
        const data = await latestUsedConnector.connect()

        this.setState({
          connector: latestUsedConnector,
          chains: latestUsedConnector.chains,
          data,
          status: 'connected'
        })
        connected = true
      }
    }

    if (!connected)
      this.setState({
        data: undefined,
        status: 'disconnected'
      })

    return this.data
  }

  private addEffects(connector?: Connector, prevConnector?: Connector) {
    prevConnector?.removeListener?.('update', this.onConnectorUpdate)
    prevConnector?.removeListener?.('disconnect', this.onConnectorDisconnect)
    prevConnector?.removeListener?.('error', this.onConnectorError)

    if (!connector) return
    connector.on?.('update', this.onConnectorUpdate)
    connector.on?.('disconnect', this.onConnectorDisconnect)
    connector.on?.('error', this.onConnectorError)
  }

  private onConnectorUpdate(data: Data<TProvider>) {
    this.setState({
      data
    })
  }

  private onConnectorDisconnect() {
    this.clearState()
  }

  private onConnectorError(error: Error) {
    this.setState({
      error
    })
  }
}

export let client: Client<Provider>

export function createClient<TProvider extends Provider = Provider>(config?: ClientConfig) {
  const client_ = new Client<TProvider>(config)
  client = client_ as unknown as Client
  return client_
}

export function getClient<TProvider extends Provider = Provider>() {
  if (!client) {
    console.warn('No client defined. Falling back to default client.')
    return new Client<TProvider>()
  }
  return client as unknown as Client<TProvider>
}
