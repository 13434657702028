import * as React from 'react'
import { useClient } from '../context'



export function useDisconnect() {
  const client = useClient()

  const disconnect = React.useCallback(async () => {
    if (client.connector)
      await client.connector.disconnect()
    
    client.clearState()
    client.clearLatestConnector()
    
  }, [client])

  return {
    disconnect
  }
}