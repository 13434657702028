import {
  IWeb3Auth,
  SafeEventEmitterProvider,
  WALLET_ADAPTERS,
  ADAPTER_STATUS,
  CHAIN_NAMESPACES,
  CustomChainConfig
} from '@web3auth/base'
import { OpenloginAdapterOptions, OpenloginAdapter, LoginParams } from '@web3auth/openlogin-adapter'

import { Connector } from './base'
import { Chain } from '../types'
import { normalizeChainId } from '../utils/chain'
import { Web3AuthNoModal, Web3AuthNoModalOptions } from '@web3auth/no-modal'
import { SwitchChainError } from '../errors'
import { TorusWalletConnectorPlugin } from '@web3auth/torus-wallet-connector-plugin'
import { EthereumPrivateKeyProvider } from '@web3auth/ethereum-provider'
import { BrowserProvider, Eip1193Provider, getAddress } from 'ethers'

interface CustomWeb3AuthNoModalOptions extends Web3AuthNoModalOptions {
  chainConfig: CustomChainConfig
}

export interface Web3AuthConnectorOptions {
  web3AuthConstructorArgs: CustomWeb3AuthNoModalOptions
  openLoginAdapterOptions: OpenloginAdapterOptions
  torusWalletUIConfig?: Partial<ConstructorParameters<typeof TorusWalletConnectorPlugin>[0]>
}

export interface OpenLoginConnectOptions extends LoginParams {}

export class Web3AuthConnector extends Connector<
  SafeEventEmitterProvider,
  Web3AuthConnectorOptions,
  OpenLoginConnectOptions
> {
  readonly name = 'Web3Auth'
  readonly ready = typeof window !== 'undefined'

  private _web3auth?: IWeb3Auth
  private pendingGetProvider?: Promise<any>
  private _torusPlugin?: TorusWalletConnectorPlugin

  constructor(config: { chains?: Chain[]; options?: Web3AuthConnectorOptions }) {
    super(config)
  }

  async connect(options?: OpenLoginConnectOptions) {
    try {
      const provider = await this.getProvider(options)
      provider.on('accountsChanged', this.onAccountsChanged as any)
      provider.on('chainChanged', this.onChainChanged as any)
      provider.on('disconnect', this.onDisconnect)
      this.emit('connect')

      const account = await this.getAccount()
      const chainId = await this.getChainId()
      const unsupported = this.isChainUnsupported(chainId)
      return {
        account,
        chain: {
          id: chainId,
          unsupported
        },
        provider: new BrowserProvider(<Eip1193Provider>provider)
      }
    } catch (error) {
      throw error
    }
  }

  getWeb3auth() {
    if (!this._web3auth) {
      throw new Error('No web3auth instance')
    }
    return this._web3auth
  }

  async disconnect() {
    const provider = await this.getProvider()
    provider.removeListener('accountsChanged', this.onAccountsChanged)
    provider.removeListener('chainChanged', this.onChainChanged)
    provider.removeListener('disconnect', this.onDisconnect)
    await this.getWeb3auth().logout()
    this._web3auth = undefined
  }

  async getAccount() {
    const signer = await this.getSigner()
    const account = await signer.getAddress()
    return getAddress(account)
  }

  async getChainId() {
    const provider = await this.getProvider()
    const chainId = await provider.request({ method: 'eth_chainId' })
    return normalizeChainId(chainId as string)
  }

  async getProvider(options?: OpenLoginConnectOptions) {
    if (!this._web3auth) {
      const web3auth = new Web3AuthNoModal(this.options.web3AuthConstructorArgs)

      const privateKeyProvider = new EthereumPrivateKeyProvider({
        config: {
          chainConfig: {
            ...this.options.web3AuthConstructorArgs.chainConfig
          }
        }
      })

      const openLoginAdapter = new OpenloginAdapter({
        ...this.options.openLoginAdapterOptions,
        privateKeyProvider
      })
      web3auth.configureAdapter(openLoginAdapter)

      this._web3auth = web3auth

      this.pendingGetProvider = new Promise(async (resolve, reject) => {
        try {
          const torusPlugin = new TorusWalletConnectorPlugin({
            torusWalletOpts: {
              ...this.options.torusWalletUIConfig?.torusWalletOpts
            },
            walletInitOptions: {
              whiteLabel: {
                theme: { isDark: true, colors: { primary: '#00a8ff' } },
                logoDark: 'https://web3auth.io/images/w3a-L-Favicon-1.svg',
                logoLight: 'https://web3auth.io/images/w3a-D-Favicon-1.svg'
              },
              showTorusButton: false,
              ...this.options.torusWalletUIConfig?.walletInitOptions
            }
          })

          await web3auth.addPlugin(torusPlugin)
          await web3auth.init()

          this._torusPlugin = torusPlugin

          if (web3auth.status !== ADAPTER_STATUS.CONNECTED && this.ready) {
            if (this.options.openLoginAdapterOptions?.adapterSettings?.uxMode === 'redirect') {
              localStorage.setItem('guwc.connector', this.name)
            }
            await this.connectTo(options)
          }

          // Wait until the Torus plugin is available.
          await new Promise((resolve) => {
            const intervalId = setInterval(() => {
              if (torusPlugin.proxyProvider) {
                clearInterval(intervalId)
                resolve(true)
              }
            }, 500)
          })
          resolve(torusPlugin.proxyProvider)
        } catch (e) {
          reject(e)
        }
      })
    }
    await this.pendingGetProvider
    if (!this._torusPlugin?.proxyProvider) {
      throw new Error('Cannot get provider')
    }
    return this._torusPlugin.proxyProvider
  }

  async getSigner() {
    const provider = new BrowserProvider(await this.getProvider())
    const signer = provider.getSigner()
    return signer
  }

  async isAuthorized() {
    try {
      const account = await this.getAccount()
      return !!account
    } catch {
      return false
    }
  }

  async switchChain(chainId: number) {
    const chain = this.chains.find((x) => x.id === chainId)
    if (!chain) throw new SwitchChainError(new Error('chain not found on connector.'))
    const currentChain = await this.getChainId()
    if (chain.id !== currentChain) {
      await this._torusPlugin?.torusWalletInstance.setProvider({
        chainId: chainId,
        host: chain.rpcUrls[0],
        blockExplorer: chain.blockExplorerUrls[0],
        networkName: chain.name
      })
    }
    return chain
  }

  protected onAccountsChanged = (accounts: string[]) => {
    if (accounts.length === 0) {
      this.emit('disconnect')
    } else {
      this.emit('update', { account: getAddress(accounts[0]) })
    }
  }

  protected onChainChanged = async (chainId: number | string) => {
    const id = normalizeChainId(chainId)
    const unsupported = this.isChainUnsupported(id)
    const provider = await this.getProvider()
    this.emit('update', {
      chain: { id, unsupported },
      provider: new BrowserProvider(<Eip1193Provider>provider) as any
    })
  }

  protected onDisconnect = () => {
    this.emit('disconnect')
  }

  private connectTo = async (options?: OpenLoginConnectOptions) => {
    await this.getWeb3auth().connectTo(WALLET_ADAPTERS.OPENLOGIN, {
      loginProvider: 'google',
      ...options
    })
  }
}
