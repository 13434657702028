import React, { useEffect, useMemo, useRef } from 'react';

import 'firebase/auth';
import firebase from 'firebase/app';
import FirebaseUI from 'firebaseui';

interface Props {
  config?: Partial<FirebaseUI.auth.Config>;
  language?: string;
  className?: string;
}

const defaultConfig: FirebaseUI.auth.Config = {
  callbacks: {
    signInSuccessWithAuthResult: (authResult) => {
      const user = authResult.user;
      if (user && !user.emailVerified) {
        user.sendEmailVerification();
        return false;
      }
      return true;
    },
  },
  signInFlow: 'popup',
  signInOptions: [
    {
      provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      customParameters: {
        prompt: 'select_account',
      },
    },
    { provider: firebase.auth.EmailAuthProvider.PROVIDER_ID },
  ],
  signInSuccessUrl: '/',
  credentialHelper: 'none',
};

const FirebaseAuthLocalized: React.FC<Props> = (props) => {
  const { className, language = 'en', config } = props;
  const ref = useRef<HTMLDivElement>(null);
  const firebaseUiWidget = useRef<FirebaseUI.auth.AuthUI>();
  const firebaseUiDeletion = useRef<Promise<void>>();
  const firebaseConfig = useMemo(() => ({ ...defaultConfig, ...config }), [config]);

  useEffect(() => {
    require('firebaseui/dist/firebaseui.css');
    require('./style.css');
  }, []);

  useEffect(() => {
    const firebaseui: typeof FirebaseUI = require(`firebaseui/dist/esm__${language}`);
    (async () => {
      if (firebaseUiDeletion) {
        // Wait to finish deleting the previous render.
        await firebaseUiDeletion.current;
      }
      const element = ref.current;
      if (element) {
        firebaseUiWidget.current = firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth());
        firebaseUiWidget.current.start(element, firebaseConfig);
      }
    })();
    return () => {
      firebaseUiDeletion.current = firebaseUiWidget.current?.delete();
      /**
       * Delete the cache and we can re-require again with other language without reload the page.
       * More info here: https://webpack.js.org/api/module-methods/#requirecache
       */
      delete require.cache[require.resolve(`firebaseui/dist/esm__${language}`)];
    };
  }, [firebaseConfig, language]);

  return <div ref={ref} className={className} />;
};

export default FirebaseAuthLocalized;
