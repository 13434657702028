import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const DialogCloseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 1,
}));

export default DialogCloseButton;
