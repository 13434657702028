import { ChangeEvent, useCallback } from 'react';

import { useAccount } from '@gusdk/gu-wallet-connector';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Icon from '@mui/material/Icon';
import ListItemButton from '@mui/material/ListItemButton';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useSnackbar } from 'notistack';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { HEADER_HEIGHT } from '~/constants/layout';
import { StyledComponentProps } from '~/types/material-ui';
import { truncateEthAddress } from '~/utils/string.utils';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  wrapper: {
    width: '100%',
    maxWidth: 640,
    padding: theme.spacing(4, 0),
  },
  paper: {
    overflow: 'hidden',
  },
  header: {
    padding: theme.spacing(2),
  },
  content: {
    paddingBottom: theme.spacing(2),
  },
  language: {
    width: 155,
    marginLeft: 'auto',
  },
  emailConfirmationWaring: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1.5),
  },
  btnLogout: {
    margin: theme.spacing(0.5, 0, 2, 0),
    textTransform: 'uppercase',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    color: theme.palette.text.secondary,
  },
  toolbar: {
    height: HEADER_HEIGHT,
  },
  toolbarContent: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  toolbarContentRight: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 0,
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      gap: 0,
    },
  },
  logo: {
    color: '#3E5B72',
    height: 30,
    display: 'block',
    pointerEvents: 'none',
    fontWeight: 700,
    whiteSpace: 'nowrap',
    [theme.breakpoints.up('sm')]: {
      height: 40,
    },
  },
}));
interface Props extends StyledComponentProps<typeof useStyles> {
  showAppbar?: boolean;
}

const AuthLayout: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { showAppbar } = props;
  const { classes, theme } = useStyles(undefined, { props: {} });
  const { t, i18n } = useTranslation();
  const { account: currentWalletAddress } = useAccount();
  const { enqueueSnackbar } = useSnackbar();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const onLanguageChange = useCallback(
    async (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const selectedLanguage = event.target.value;
      i18n.changeLanguage(selectedLanguage);
    },
    [i18n]
  );

  const onCopyAddress = useCallback(() => {
    enqueueSnackbar(t('common.copied'), {
      variant: 'info',
    });
  }, [enqueueSnackbar, t]);

  return (
    <Container className={classes.root}>
      {showAppbar && (
        <AppBar className={classes.appBar} color="inherit">
          <Toolbar className={classes.toolbar}>
            <div className={classes.toolbarContent}>
              {/* <RouterLink to="/"> */}
              <Typography variant="h5">GU coin paymens</Typography>
              {/* </RouterLink> */}
              <div className={classes.toolbarContentRight}>
                {isSmallScreen ? (
                  <div />
                ) : (
                  <>
                    {currentWalletAddress && (
                      <ListItemButton sx={{ minWidth: 0, width: 'auto' }}>
                        <Icon component="img" src="/images/wallet-account-avatar.svg" sx={{ marginRight: 1 }} />
                        <Box sx={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                          <CopyToClipboard text={currentWalletAddress} onCopy={onCopyAddress}>
                            <div>{truncateEthAddress(currentWalletAddress)}</div>
                          </CopyToClipboard>
                        </Box>
                      </ListItemButton>
                    )}
                  </>
                )}
              </div>
            </div>
          </Toolbar>
        </AppBar>
      )}
      <div className={classes.wrapper}>
        {showAppbar && <Toolbar />}
        <div className={classes.paper}>
          <div className={classes.content}>
            <div>{props.children}</div>
          </div>
        </div>
        <div className={classes.language}>
          <TextField
            name="language"
            onChange={onLanguageChange}
            value={i18n.language}
            select
            color="primary"
            variant="outlined"
            fullWidth
            hiddenLabel
            size="small"
          >
            {languageOptions.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
    </Container>
  );
};

export default AuthLayout;

export const languageOptions = [
  {
    value: 'ja',
    label: 'Japanese',
  },
  {
    value: 'en',
    label: 'English',
  },
];
