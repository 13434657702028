import * as React from 'react'
import { I18nextProvider } from 'react-i18next'
import { Client } from '../core/client'
import i18n from './i18n'

export const Context = React.createContext<Client>(undefined)

export function GUWalletConnectorProvider({ children, client, language }: React.PropsWithChildren<{ client: Client, language?: 'en' | 'ja' }>) {
  React.useEffect(() => {
    (async () => {
      if (client.latestConnector) {
        await client.reconnect()
      }
    })()
  }, [])

  React.useEffect(() => {
    i18n.changeLanguage(language || 'en');
  }, [language]);

  return (
    <Context.Provider value={client}>
      <I18nextProvider i18n={i18n}>
        {children}
      </I18nextProvider>
    </Context.Provider>
  )
}

export function useClient() {
  const client = React.useContext(Context)
  const forceUpdate = React.useReducer((c) => c + 1, 0)[1]

  React.useEffect(() => {
    const listener = () => {
      forceUpdate()
    }
    const unsubscribe = client.store.subscribe(listener)

    return unsubscribe as any
  }, [])

  if (!client) 
    throw new Error("`useClient` must used with Provider")
  
  return client
}
