import React, { ReactElement, useCallback, useMemo, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import WalletConnectorDialog from '../WalletConnectorDialog';
import { useConnect } from '../../hooks/useConnect';
import { StyledComponentProps } from '../../types';

const useStyles = makeStyles()((theme) => ({}));

interface Props extends StyledComponentProps<typeof useStyles> {
  children: ReactElement;
}

const WalletRequired: React.FC<Props> = ({ children }) => {
  const { activeConnector } = useConnect();
  const [openWalletConnectorDialog, setOpenWalletConnectorDialog] = useState(false);

  const onCloseWalletConnectorDialog = useCallback(() => {
    setOpenWalletConnectorDialog(false);
  }, []);

  const onClick = useCallback(
    async (event: any) => {
      const provider = await activeConnector?.getProvider();
      if (!provider) {
        setOpenWalletConnectorDialog(true);
      } else {
        children.props?.onClick(event);
      }
    },
    [activeConnector, children.props],
  );

  const renderchildren = useMemo(() => {
    return React.cloneElement(children, { onClick });
  }, [children, onClick]);

  return (
    <>
      <WalletConnectorDialog
        open={openWalletConnectorDialog}
        onClose={onCloseWalletConnectorDialog}
        onNext={children.props?.onClick}
      />
      {renderchildren}
    </>
  );
};

export default WalletRequired;
