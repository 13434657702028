import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = { context: { apiName: 'checkout-api' } } as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
};

export type Mutation = {
  updatePayment: Payment;
};

export type MutationUpdatePaymentArgs = {
  input: UpdatePaymentInput;
};

export type Payment = {
  /** Amount */
  amount: Scalars['Float'];
  /** Buyer wallet address */
  buyerWalletAddress?: Maybe<Scalars['String']>;
  /** Created at */
  createdAt: Scalars['Date'];
  /** Description */
  description: Scalars['String'];
  /** Expired at */
  expiredAt: Scalars['Date'];
  /** Merchant wallet address */
  merchantWalletAddress: Scalars['String'];
  /** Signature */
  signature: Scalars['String'];
  /** State */
  state: PaymentStateEnum;
  /** Token */
  token: Token;
  /** Transaction hash */
  transactionHash?: Maybe<Scalars['String']>;
  /** Updated at */
  updatedAt?: Maybe<Scalars['Date']>;
  /** Uuid */
  uuid: Scalars['String'];
};

export enum PaymentStateEnum {
  Confirming = 'CONFIRMING',
  Done = 'DONE',
  Expired = 'EXPIRED',
  Failure = 'FAILURE',
  Waiting = 'WAITING',
}

export type Query = {
  getPayment: Payment;
  getPaymentContractAddress: Scalars['String'];
};

export type QueryGetPaymentArgs = {
  paymentUuid: Scalars['String'];
};

export type Token = {
  /** Created at */
  createdAt: Scalars['Date'];
  /** Description */
  description?: Maybe<Scalars['String']>;
  /** Name */
  name: Scalars['String'];
  /** Symbol */
  symbol: Scalars['String'];
  /** Token address */
  tokenAddress: Scalars['String'];
  /** Updated at */
  updatedAt?: Maybe<Scalars['Date']>;
  /** Uuid */
  uuid: Scalars['String'];
};

export type UpdatePaymentInput = {
  /** Payment uuid */
  paymentUuid: Scalars['String'];
  /** Transaction hash of current payment */
  transactionHash: Scalars['String'];
};

export type UpdatePaymentMutationVariables = Exact<{
  input: UpdatePaymentInput;
}>;

export type UpdatePaymentMutation = {
  updatePayment: {
    uuid: string;
    amount: number;
    description: string;
    merchantWalletAddress: string;
    buyerWalletAddress?: string | null;
    transactionHash?: string | null;
    state: PaymentStateEnum;
    createdAt: string;
    updatedAt?: string | null;
    token: {
      uuid: string;
      name: string;
      symbol: string;
      description?: string | null;
      tokenAddress: string;
      createdAt: string;
      updatedAt?: string | null;
    };
  };
};

export type GetPaymentContractAddressQueryVariables = Exact<{ [key: string]: never }>;

export type GetPaymentContractAddressQuery = { getPaymentContractAddress: string };

export type GetPaymentQueryVariables = Exact<{
  paymentUuid: Scalars['String'];
}>;

export type GetPaymentQuery = {
  getPayment: {
    uuid: string;
    amount: number;
    description: string;
    merchantWalletAddress: string;
    buyerWalletAddress?: string | null;
    transactionHash?: string | null;
    state: PaymentStateEnum;
    signature: string;
    createdAt: string;
    expiredAt: string;
    updatedAt?: string | null;
    token: {
      uuid: string;
      name: string;
      symbol: string;
      description?: string | null;
      tokenAddress: string;
      createdAt: string;
      updatedAt?: string | null;
    };
  };
};

export const UpdatePaymentDocument = gql`
  mutation updatePayment($input: UpdatePaymentInput!) {
    updatePayment(input: $input) {
      uuid
      amount
      description
      merchantWalletAddress
      buyerWalletAddress
      transactionHash
      state
      token {
        uuid
        name
        symbol
        description
        tokenAddress
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export type UpdatePaymentMutationFn = Apollo.MutationFunction<UpdatePaymentMutation, UpdatePaymentMutationVariables>;

/**
 * __useUpdatePaymentMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMutation, { data, loading, error }] = useUpdatePaymentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePaymentMutation, UpdatePaymentMutationVariables>(UpdatePaymentDocument, options);
}
export type UpdatePaymentMutationHookResult = ReturnType<typeof useUpdatePaymentMutation>;
export type UpdatePaymentMutationResult = Apollo.MutationResult<UpdatePaymentMutation>;
export type UpdatePaymentMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentMutation,
  UpdatePaymentMutationVariables
>;
export const GetPaymentContractAddressDocument = gql`
  query getPaymentContractAddress {
    getPaymentContractAddress
  }
`;

/**
 * __useGetPaymentContractAddressQuery__
 *
 * To run a query within a React component, call `useGetPaymentContractAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentContractAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentContractAddressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentContractAddressQuery(
  baseOptions?: Apollo.QueryHookOptions<GetPaymentContractAddressQuery, GetPaymentContractAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPaymentContractAddressQuery, GetPaymentContractAddressQueryVariables>(
    GetPaymentContractAddressDocument,
    options
  );
}
export function useGetPaymentContractAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentContractAddressQuery, GetPaymentContractAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPaymentContractAddressQuery, GetPaymentContractAddressQueryVariables>(
    GetPaymentContractAddressDocument,
    options
  );
}
export type GetPaymentContractAddressQueryHookResult = ReturnType<typeof useGetPaymentContractAddressQuery>;
export type GetPaymentContractAddressLazyQueryHookResult = ReturnType<typeof useGetPaymentContractAddressLazyQuery>;
export type GetPaymentContractAddressQueryResult = Apollo.QueryResult<
  GetPaymentContractAddressQuery,
  GetPaymentContractAddressQueryVariables
>;
export const GetPaymentDocument = gql`
  query getPayment($paymentUuid: String!) {
    getPayment(paymentUuid: $paymentUuid) {
      uuid
      amount
      description
      merchantWalletAddress
      buyerWalletAddress
      transactionHash
      state
      token {
        uuid
        name
        symbol
        description
        tokenAddress
        createdAt
        updatedAt
      }
      signature
      createdAt
      expiredAt
      updatedAt
    }
  }
`;

/**
 * __useGetPaymentQuery__
 *
 * To run a query within a React component, call `useGetPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentQuery({
 *   variables: {
 *      paymentUuid: // value for 'paymentUuid'
 *   },
 * });
 */
export function useGetPaymentQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentQuery, GetPaymentQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPaymentQuery, GetPaymentQueryVariables>(GetPaymentDocument, options);
}
export function useGetPaymentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentQuery, GetPaymentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPaymentQuery, GetPaymentQueryVariables>(GetPaymentDocument, options);
}
export type GetPaymentQueryHookResult = ReturnType<typeof useGetPaymentQuery>;
export type GetPaymentLazyQueryHookResult = ReturnType<typeof useGetPaymentLazyQuery>;
export type GetPaymentQueryResult = Apollo.QueryResult<GetPaymentQuery, GetPaymentQueryVariables>;
