import React, { useCallback } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'

import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'

import { StyledComponentProps } from '../../../types'

const useStyles = makeStyles()((theme) => ({}))

interface Props extends StyledComponentProps<typeof useStyles> {
  open: boolean
  onClose: () => void
}

const UnsupportedBrowserDialog: React.FC<Props> = (props) => {
  const { open, onClose } = props
  const { classes } = useStyles(undefined, { props: { classes: props.classes } })
  const { t } = useTranslation()

  const onClick = useCallback(() => {
    window.open('https://www.lunascape.org/browser/mobile', '_blank')
  }, [])

  return (
    <Dialog open={open} data-testid="unsupported-browser-dialog">
      <DialogTitle>
        <Stack direction="row" alignItems={'flex-start'} width={'100%'} justifyContent={'space-between'}>
          <Typography variant="h6" color="primary" component="p" fontWeight="bold" sx={{ flexGrow: 1 }}>
            {t('dialog_unsupported_browser.title')}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Typography variant="body1" color="textSecondary" component="p">
          {t('dialog_unsupported_browser.description')}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body1">{t('dialog_unsupported_browser.content')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClick} sx={{ textTransform: 'inherit' }}>
          {t('use_lunascape_mobile')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UnsupportedBrowserDialog
