import React, { useCallback } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'

import { StyledComponentProps } from '../../../types'

const useStyles = makeStyles()((theme) => ({}))

interface Props extends StyledComponentProps<typeof useStyles> {
  open: boolean
  onNext?: () => void
  onClose: () => void
}

const WalletLockDialog: React.FC<Props> = (props) => {
  const { open, onClose, onNext } = props
  const { classes } = useStyles(undefined, { props: { classes: props.classes } })
  const { t } = useTranslation()
  const { enqueueSnackbar } = useSnackbar()

  const onClickUnlock = useCallback(async () => {
    try {
      await window?.ethereum?.request({ method: 'eth_requestAccounts' })
      onClose()
      onNext?.()
    } catch (error: any) {
      enqueueSnackbar(t('unlock_wallet_failed', { message: error.message }), { variant: 'error' })
    }
  }, [onClose, onNext, enqueueSnackbar, t])

  return (
    <Dialog open={open} onClose={onClose} data-testid="wallet-lock-dialog">
      <DialogTitle>
        <Stack direction="row" alignItems={'flex-start'} width={'100%'} justifyContent={'space-between'}>
          <Typography variant="h6" color="primary" component="p" fontWeight="bold">
            {t('wallet_lock_dialog.title')}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Typography variant="body1" color="textSecondary" component="p">
          {t('wallet_lock_dialog.description')}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body1">{t('wallet_lock_dialog.content')}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={onClickUnlock}>
          {t('unlock')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default WalletLockDialog
