import { Signer } from "ethers";
import { useEffect, useState } from "react";
import { useClient } from "../context";

export function useSigner() {
  const [signer, setSigner] = useState<Signer>(null)
  const client = useClient()
  const chainId = client.data?.chain?.id
  const account = client.data?.account
  const connector = client.connector

  useEffect(() => {
    (async () => {
      const signer = (await client.connector?.getSigner?.()) || null;
      setSigner(signer)
    })()
  }, [account, chainId, connector])

  return signer
}