import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { GridLogicOperator } from '@mui/x-data-grid-pro';

import {
  BLACK_COLOR,
  WHITE_COLOR,
  TORY_BLUE_COLOR,
  NAVY_BLUE_COLOR,
  PATTENS_BLUE_COLOR,
  MID_NIGHT_COLOR,
  ALICE_BLUE_COLOR,
  ALICE_BLUE_2_COLOR,
  NOBEL_COLOR,
  CHARCOAL_COLOR,
  DARK_CERULEAN_COLOR,
  NIGHT_RIDER_COLOR,
  GAINSBORO_COLOR,
  GAINSBORO_2_COLOR,
  SNOW_COLOR,
  DODGER_BLUE_COLOR,
  DODGER_BLUE_2_COLOR,
  RED_ORANGE_COLOR,
  FRUIT_SALAD_COLOR,
  ORANGE_PEEL_COLOR,
  SILVER_COLOR,
  NEON_BLUE_COLOR,
  TRUE_BLUE,
} from '../constants/colors';
export const generateTheme = (...args: object[]) => {
  const theme = responsiveFontSizes(
    createTheme(
      {
        palette: {
          primary: {
            main: TRUE_BLUE,
          },
        },
        colors: {
          black: BLACK_COLOR,
          white: WHITE_COLOR,
          toryBlue: TORY_BLUE_COLOR,
          navyBlue: NAVY_BLUE_COLOR,
          pattensBlue: PATTENS_BLUE_COLOR,
          midnight: MID_NIGHT_COLOR,
          aliceBlue: ALICE_BLUE_COLOR,
          aliceBlue2: ALICE_BLUE_2_COLOR,
          nobel: NOBEL_COLOR,
          charcoal: CHARCOAL_COLOR,
          darkCerulean: DARK_CERULEAN_COLOR,
          nightRider: NIGHT_RIDER_COLOR,
          gainsboro: GAINSBORO_COLOR,
          gainsboro2: GAINSBORO_2_COLOR,
          snow: SNOW_COLOR,
          dodgerBlue: DODGER_BLUE_COLOR,
          dodgerBlue2: DODGER_BLUE_2_COLOR,
          redOrange: RED_ORANGE_COLOR,
          fruitSalad: FRUIT_SALAD_COLOR,
          orangePeel: ORANGE_PEEL_COLOR,
          silver: SILVER_COLOR,
          neonBlue: NEON_BLUE_COLOR,
          trueBlue: TRUE_BLUE,
        },
      },
      ...args
    )
  );

  theme.components = {
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: theme.spacing(1, 3, 3, 3),
          '& > :not(:first-of-type)': {
            marginLeft: theme.spacing(2),
          },
        },
        spacing: {
          '& > :not(:first-of-type)': {
            marginLeft: theme.spacing(3),
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingRight: theme.spacing(7), // Fix title overlap close dialog button
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: 4,
          height: 48,
          alignItems: 'center',
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 40,
          '& img': {
            width: 23,
            height: 23,
          },
        },
      },
    },
    MuiDataGrid: {
      ...theme.components?.MuiDataGrid,
      defaultProps: {
        ...theme.components?.MuiDataGrid?.defaultProps,
        componentsProps: {
          filterPanel: {
            logicOperators: [GridLogicOperator.And],
            getColumnForNewFilter({ currentFilters, columns }) {
              const filteredFields = currentFilters?.map(({ field }) => field);
              const columnForNewFilter = columns
                .filter((colDef) => colDef.filterable && !filteredFields.includes(colDef.field))
                .find((colDef) => colDef.filterOperators?.length);
              return columnForNewFilter?.field ?? null;
            },
          },
          toolbar: {
            csvOptions: {
              disableToolbarButton: true,
            },
            printOptions: {
              disableToolbarButton: true,
            },
          },
        },
      },
      styleOverrides: {
        panel: {
          right: '0 !important',
          top: '0 !important',
          left: '0 !important',
          maxWidth: 'calc(100% - 32px)',
          [theme.breakpoints.up('sm')]: {
            maxWidth: 500,
          },
          '& .MuiDataGrid-filterFormColumnInput': {
            width: 'unset',
            flexGrow: 1,
          },
          '& .MuiDataGrid-filterFormOperatorInput': {
            width: 'unset',
            flexGrow: 1,
          },
          '& .MuiDataGrid-filterFormValueInput': {
            width: 'unset',
            flexGrow: 1,
          },
        },
        columnHeader: {
          outline: 'none !important',
        },
        cell: {
          whiteSpace: 'pre-wrap !important' as any,
          maxHeight: 'none !important',
          outline: 'none !important',
        },
        row: {
          maxHeight: 'none !important',
        },
      },
    },
  };
  return theme;
};
