import { createRef, useEffect, useMemo } from 'react';

import {
  createClient,
  GUWalletConnectorProvider,
  InjectedConnector,
  WalletConnectConnector,
  Web3AuthConnector,
} from '@gusdk/gu-wallet-connector';
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import * as dataGridPro from '@mui/x-data-grid-pro';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import firebase from 'firebase/app';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation } from 'react-router-dom';

import { CHAIN_CONFIG } from './config/chainConfig';
import { setUpDataGrid } from './config/dataGrid';
import { WithApolloClient } from './graphql/client';
import { WithConfirmationDialog } from './hooks/use-confirmation-dialog';
import { generateTheme } from './styles/theme';
import { setupYupLocale } from './utils/yup.util';

import { env } from '~/env';

const firebaseConfig = {
  apiKey: env.REACT_APP_FIREBASE_PUBLIC_API_KEY,
  authDomain: env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: env.REACT_APP_FIREBASE_APP_ID,
};

firebase.initializeApp(firebaseConfig);

const notistackRef = createRef<SnackbarProvider>();
const onClickDismiss = (key: SnackbarKey) => () => {
  notistackRef.current?.closeSnackbar(key);
};

const client = createClient({
  connectors: [
    new InjectedConnector(),
    new WalletConnectConnector({
      options: {
        projectId: env.REACT_APP_WALLET_CONNECT_PROJECT_ID,
      },
    }),
    new Web3AuthConnector({
      options: {
        web3AuthConstructorArgs: {
          clientId: env.REACT_APP_W3A_CLIENT_ID,
          web3AuthNetwork: env.REACT_APP_W3A_NETWORK,
          chainConfig: CHAIN_CONFIG[env.REACT_APP_W3A_CHAIN],
        },
        openLoginAdapterOptions: {
          adapterSettings: {
            uxMode: 'redirect',
            loginConfig: {
              jwt: {
                name: 'firebase',
                verifier: env.REACT_APP_W3A_VERIFIER_NAME,
                typeOfLogin: 'jwt',
                clientId: env.REACT_APP_W3A_CLIENT_ID,
              },
            },
          },
          loginSettings: {
            mfaLevel: 'none',
          },
        },
      },
    }),
  ],
});

const App: React.FC = (props) => {
  const { t, i18n } = useTranslation();

  const theme = useMemo(() => {
    const theme = generateTheme(i18n.language === 'ja' ? dataGridPro.jaJP : dataGridPro.enUS);
    return theme;
  }, [i18n.language]);

  useMemo(() => {
    setupYupLocale(t);
    setUpDataGrid(t);
  }, [t]);

  useEffect(() => {
    const currentLanguage = window.localStorage.getItem('language');
    if (currentLanguage !== i18n.language) {
      window.localStorage.setItem('language', i18n.language);
    }
  }, [i18n.language]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <SnackbarProvider
          ref={notistackRef}
          anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          action={(key) => (
            <IconButton onClick={onClickDismiss(key)} size="small" style={{ color: 'white' }}>
              <CloseIcon />
            </IconButton>
          )}
        >
          <WithConfirmationDialog>
            <GUWalletConnectorProvider client={client} language={i18n.language as 'en' | 'ja'}>
              <WithApolloClient>
                <WrapPage>
                  <Outlet />
                </WrapPage>
              </WithApolloClient>
            </GUWalletConnectorProvider>
          </WithConfirmationDialog>
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;

const WrapPage: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <>{props.children}</>;
};
