import React, { useEffect } from 'react';

import { useAccount } from '@gusdk/gu-wallet-connector';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useParams, useSearchParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import ErrorPage from '../error';

import WaitingScreen from './waiting-screen';

import AuthLayout from '~/components/auth-layout';
import ScreenLoading from '~/components/screen-loading';
import { AppRouteEnum } from '~/enums/route.enum';
import { env } from '~/env';
import {
  PaymentStateEnum,
  useGetPaymentContractAddressQuery,
  useGetPaymentQuery,
  useUpdatePaymentMutation,
} from '~/graphql/checkout/types';
import { useFormatCurrency } from '~/hooks/use-format-currency';
import { useLogout } from '~/hooks/use-logout';
import { getPaymentStateEnumI18n } from '~/utils/render.util';

const useStyles = makeStyles()((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.divider}`,
    height: '100%',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}));

const PaymentPage: React.FC = () => {
  const { classes } = useStyles(undefined, { props: {} });
  const { t, i18n } = useTranslation();
  const { account, status } = useAccount();
  const { paymentUuid } = useParams<{ paymentUuid: string }>();
  const [searchParams] = useSearchParams();
  const callbackUrl = searchParams.get('callback') || '';
  const {
    data: getPaymentData,
    loading: loadingGetPayment,
    error: getPaymentError,
  } = useGetPaymentQuery({
    fetchPolicy: 'cache-first',
    pollInterval: 5000,
    variables: {
      paymentUuid: paymentUuid || '',
    },
  });
  const {
    data: getPaymentContractAddressData,
    loading: loadingGetPaymentContractAddress,
    error: getPaymentContractAddressError,
  } = useGetPaymentContractAddressQuery({
    fetchPolicy: 'cache-first',
  });
  const [updatePayment] = useUpdatePaymentMutation();
  const { formatCurrency } = useFormatCurrency();
  const logout = useLogout();

  useEffect(() => {
    document.title = t('payment_page.title', { paymentId: paymentUuid });
  }, [paymentUuid, t]);

  if (status === 'connecting' || status === 'reconnecting' || loadingGetPayment || loadingGetPaymentContractAddress) {
    return <ScreenLoading />;
  }

  if (getPaymentError || !getPaymentData || getPaymentContractAddressError || !getPaymentContractAddressData) {
    return <ErrorPage code={404} message={(getPaymentError || getPaymentContractAddressError)?.message} />;
  }

  return (
    <AuthLayout>
      <Paper className={classes.paper} elevation={0}>
        <Typography variant="h4" textAlign="center">
          {t('checkout')}
        </Typography>
        <Typography variant="h4" fontWeight="bold" textAlign="center" marginBottom={5}>
          {t('app_name')}
        </Typography>

        <Typography variant="body1" whiteSpace="pre-wrap" marginBottom={2}>
          {t('common.information')}
        </Typography>
        <Typography variant="body1" fontWeight="bold" whiteSpace="pre-wrap" marginBottom={4}>
          {getPaymentData.getPayment.description}
        </Typography>

        <Typography variant="body1" whiteSpace="pre-wrap" marginBottom={2}>
          {t('pay')}
        </Typography>
        <Typography variant="body1" fontWeight="bold" whiteSpace="pre-wrap" marginBottom={4}>
          {formatCurrency(getPaymentData.getPayment.amount, {
            tokenSymbol: getPaymentData.getPayment.token.symbol,
          })}
        </Typography>
        {status !== 'connected' ? (
          <>
            <Button
              component={RouterLink}
              to={`${AppRouteEnum.SIGN_IN}?method=wallet`}
              state={{ paymentUuid, callbackUrl }}
              variant="contained"
              fullWidth
              size="large"
              sx={{ marginBottom: 2 }}
            >
              {t('login_with_wallet')}
            </Button>
            {!env.REACT_APP_DISABLE_W3A && (
              <Button
                component={RouterLink}
                to={`${AppRouteEnum.SIGN_IN}?method=sns`}
                state={{ paymentUuid, callbackUrl }}
                variant="contained"
                fullWidth
                size="large"
                sx={{ marginBottom: 2 }}
              >
                {t('login_with_sns')}
              </Button>
            )}
          </>
        ) : getPaymentData.getPayment.state === PaymentStateEnum.Waiting ? (
          <WaitingScreen
            payment={getPaymentData.getPayment}
            paymentContractAddress={getPaymentContractAddressData?.getPaymentContractAddress}
          />
        ) : (
          <>
            <Typography variant="body1" whiteSpace="pre-wrap" marginBottom={2}>
              {t('status')}
            </Typography>
            <Typography variant="body1" fontWeight="bold" whiteSpace="pre-wrap" marginBottom={4}>
              {getPaymentStateEnumI18n(t, getPaymentData.getPayment.state)}
            </Typography>
            {getPaymentData.getPayment.transactionHash && (
              <>
                <Typography variant="body1" whiteSpace="pre-wrap" marginBottom={2}>
                  {t('transaction_hash')}
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  whiteSpace="pre-wrap"
                  sx={{ wordBreak: 'break-all', display: 'block' }}
                  marginBottom={4}
                  component={Link}
                  href={`${env.REACT_APP_BLOCK_EXPLORER_URL}/${getPaymentData.getPayment.transactionHash}`}
                  target="_blank"
                  color="text.secondary"
                >
                  {getPaymentData.getPayment.transactionHash}
                </Typography>
              </>
            )}
            {getPaymentData.getPayment.state === PaymentStateEnum.Done && (
              <>
                <Button
                  variant="contained"
                  fullWidth
                  size="large"
                  sx={{ marginBottom: 4 }}
                  component={Link}
                  href={callbackUrl}
                >
                  {t('go_back')}
                </Button>
              </>
            )}
          </>
        )}
      </Paper>
    </AuthLayout>
  );
};

export default PaymentPage;
