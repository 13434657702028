import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { AppRouteEnum } from '~/enums/route.enum';
import { env } from '~/env';
import { getErrorPageMessageI18n } from '~/utils/render.util';

const useStyles = makeStyles()((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    minHeight: '100vh',
  },
  wrapper: {
    width: '100%',
    maxWidth: 550,
  },
  paper: {
    border: `1px solid ${theme.palette.divider}`,
    height: '100%',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  content: {
    textAlign: 'center',
  },
  logo: {
    width: 279,
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    marginBottom: theme.spacing(3),
  },
}));
interface Props {
  code: number;
  message?: string;
}

export enum ErrorPageType {
  notFound = 404,
}

const ErrorPage: React.FC<Props> = (props) => {
  const { code, message } = props;
  const { classes } = useStyles(undefined, { props: {} });
  const { t } = useTranslation();

  return (
    <Container className={classes.root}>
      <div className={classes.wrapper}>
        <Paper className={classes.paper} elevation={0}>
          <div className={classes.content}>
            <Typography variant="h4" fontWeight="bold" marginBottom={3}>
              {code}
            </Typography>
            <Typography variant="body1" whiteSpace="pre-wrap" marginBottom={4}>
              {message ?? getErrorPageMessageI18n(t, code)}
            </Typography>

            <Button component={RouterLink} to={env.REACT_APP_HELP_URL} fullWidth size="large" variant="text">
              {t('click_here_to_contact_us')}
            </Button>
          </div>
        </Paper>
      </div>
    </Container>
  );
};

export default ErrorPage;
