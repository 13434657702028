import React, { useCallback } from 'react';

import assert from 'assert';

import { ERC20__factory, GUCoinPayment__factory } from '@gu-corp/gu-coin-payments-contracts';
import { useAccount, useConnect, useSwitchNetwork } from '@gusdk/gu-wallet-connector';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { ethers } from 'ethers';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import { uuidToBytes32 } from 'uuid-to-bytes32';

import { env } from '~/env';
import { Payment, useUpdatePaymentMutation } from '~/graphql/checkout/types';
import { useConfirmationDialog } from '~/hooks/use-confirmation-dialog';
import useCountdown from '~/hooks/use-count-down';
import { useFormatCurrency } from '~/hooks/use-format-currency';
import { useLogout } from '~/hooks/use-logout';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.divider}`,
    height: '100%',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
}));
interface Props extends StyledComponentProps<typeof useStyles> {
  payment: Payment;
  paymentContractAddress: string;
}
const provider = new ethers.JsonRpcProvider(env.REACT_APP_NETWORK_RPC_URL || '');

const WaitingScreen: React.FC<Props> = (props) => {
  const { payment, paymentContractAddress } = props;
  const { account } = useAccount();
  const { switchNetwork } = useSwitchNetwork();
  const { activeConnector } = useConnect();
  const { enqueueSnackbar } = useSnackbar();
  const { confirmDialog } = useConfirmationDialog();
  const { formatCurrency } = useFormatCurrency();
  const { t, i18n } = useTranslation();

  const logout = useLogout();
  const [updatePayment] = useUpdatePaymentMutation();
  const timeLeft = useCountdown(new Date(payment.expiredAt));

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<{}>({});

  const onPay = useCallback(async () => {
    try {
      const network = await provider.getNetwork();
      await switchNetwork(Number(network.chainId));

      await new Promise((done) => setTimeout(done, 1000)); // Wait for update chainId in refSigner
      if (Number(await activeConnector.getChainId()) !== Number(network.chainId)) {
        return;
      }
      const signer = await activeConnector.getSigner();
      const paymentContract = GUCoinPayment__factory.connect(paymentContractAddress, signer);
      const erc20 = ERC20__factory.connect(payment.token.tokenAddress, signer);
      const userBalance = await erc20.balanceOf(account);
      const decimals = await erc20.decimals();
      const parseAmount = ethers.parseUnits(payment.amount.toString(), decimals);

      if (parseAmount > userBalance) {
        throw new Error(t('yup_locale.test.excess_balance'));
      }

      const isConfirm = await confirmDialog({
        content: (
          <>
            <Typography variant="body1" textAlign="right">
              {t('your_balance')}:{' '}
              {formatCurrency(ethers.formatUnits(userBalance, decimals), {
                tokenSymbol: payment.token.symbol,
              })}
            </Typography>
          </>
        ),
      });

      if (!isConfirm) {
        return;
      }

      await (await erc20.approve(await paymentContract.getAddress(), parseAmount)).wait();

      const tx = await paymentContract.purchase({
        orderId: uuidToBytes32(payment.uuid),
        to: payment.merchantWalletAddress,
        amount: parseAmount,
        currency: payment.token.tokenAddress,
        startTime: Math.floor(new Date(payment.createdAt).getTime() / 1000),
        endTime: Math.floor(new Date(payment.expiredAt).getTime() / 1000),
        signature: payment.signature,
      });
      await updatePayment({
        variables: {
          input: {
            paymentUuid: payment.uuid,
            transactionHash: tx.hash,
          },
        },
      });
    } catch (error: any) {
      enqueueSnackbar(error?.reason || error?.data?.message || error.message, {
        variant: 'error',
      });
    }
  }, [
    account,
    activeConnector,
    confirmDialog,
    enqueueSnackbar,
    formatCurrency,
    payment,
    paymentContractAddress,
    switchNetwork,
    t,
    updatePayment,
  ]);

  return (
    <>
      <Typography variant="body1" whiteSpace="pre-wrap" marginBottom={2}>
        {t('connected_wallet_address')}
      </Typography>
      <Typography variant="body1" fontWeight="bold" whiteSpace="pre-wrap" marginBottom={4}>
        {account}
      </Typography>
      <Button
        variant="contained"
        fullWidth
        size="large"
        sx={{ marginBottom: 2 }}
        onClick={handleSubmit(onPay)}
        disabled={isSubmitting || timeLeft === 0}
        endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
      >
        {t('pay')} ({moment.utc(timeLeft).format(t('common.time_format'))})
      </Button>
      <Button
        variant="contained"
        fullWidth
        size="large"
        sx={{ marginBottom: 4 }}
        disabled={isSubmitting}
        onClick={logout}
      >
        {t('common.logout')}
      </Button>
    </>
  );
};

export default WaitingScreen;
