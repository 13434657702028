import {
  getGridStringOperators,
  GRID_STRING_COL_DEF,
  GRID_SINGLE_SELECT_COL_DEF,
  getGridSingleSelectOperators,
  jaJP,
  getGridDateOperators,
} from '@mui/x-data-grid-pro';
import { generateLicense, LicenseInfo } from '@mui/x-license-pro';
import { TFunction } from 'i18next';

LicenseInfo.setLicenseKey(
  generateLicense({
    expiryDate: new Date(4000, 10, 10),
    orderNumber: '1',
    licensingModel: 'subscription',
    scope: 'premium',
  })
);

export const setUpDataGrid = (t: TFunction) => {
  GRID_STRING_COL_DEF.filterOperators = getGridStringOperators().filter(({ value }) => value === 'equals');
  GRID_SINGLE_SELECT_COL_DEF.filterOperators = getGridSingleSelectOperators().filter(({ value }) => value === 'is');
  jaJP.components.MuiDataGrid.defaultProps.localeText.filterPanelRemoveAll = t<string>('remove_all');
};
