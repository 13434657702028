import { isPhoneNumber, isNumberString } from 'class-validator';
import moment from 'moment';
import { TFunction } from 'react-i18next';
import web3 from 'web3';
import * as yup from 'yup';
const customLocale = yup.defaultLocale as yup.CustomLocaleObject;

yup.addMethod(yup.string, 'ethAddress', function (message: string | undefined = customLocale.string.ethAddress) {
  return this.test({
    name: 'ethAddress',
    message,
    test: (value) => {
      return value ? web3.utils.isAddress(value) : true;
    },
  });
});

yup.addMethod<yup.StringSchema>(
  yup.string,
  'katakana',
  function (message: string | undefined = customLocale.string.katakana) {
    return this.matches(/^[\u30a0-\u30ff]+$/, { message, excludeEmptyString: true });
  }
);

yup.addMethod<yup.StringSchema>(
  yup.string,
  'katakanaAddress',
  function (message: string | undefined = customLocale.string.katakana) {
    return this.matches(/^[ァ-ン０-９ー－−]+$/, { message, excludeEmptyString: true });
  }
);

yup.addMethod(yup.string, 'phoneNumber', function (message: string | undefined = customLocale.string.phoneNumber) {
  return this.test({
    name: 'phoneNumber',
    message,
    test: (value) => {
      return value ? isPhoneNumber(value) : true;
    },
  });
});

yup.addMethod(
  yup.string,
  'noLeadingTrailingSpaces',
  function (message: string | undefined = customLocale.string.noLeadingTrailingSpaces) {
    return this.test({
      name: 'noLeadingTrailingSpaces',
      message,
      test: (value) => {
        return value ? value.length === value.trim().length : true;
      },
    });
  }
);

yup.addMethod(yup.string, 'numberString', function (message: string | undefined = customLocale.string.numberString) {
  return this.test({
    name: 'numberString',
    message,
    test: (value) => {
      return value ? isNumberString(value) : true;
    },
  });
});

export const setupYupLocale = (t: TFunction) => {
  yup.setLocale({
    mixed: {
      default: t('yup_locale.mixed.default'),
      required: t('yup_locale.mixed.required'),
      defined: t('yup_locale.mixed.defined'),
      oneOf: t('yup_locale.mixed.oneOf'),
      notOneOf: t('yup_locale.mixed.notOneOf'),
    },
    string: {
      ethAddress: t('yup_locale.string.ethAddress'),
      katakana: t('yup_locale.string.katakana'),
      phoneNumber: t('yup_locale.string.phoneNumber'),
      numberString: t('yup_locale.string.numberString'),
      length: t('yup_locale.string.length'),
      min: t('yup_locale.string.min'),
      max: t('yup_locale.string.max'),
      matches: t('yup_locale.string.matches'),
      email: t('yup_locale.string.email'),
      url: t('yup_locale.string.url'),
      uuid: t('yup_locale.string.uuid'),
      trim: t('yup_locale.string.trim'),
      lowercase: t('yup_locale.string.lowercase'),
      uppercase: t('yup_locale.string.uppercase'),
      noLeadingTrailingSpaces: t('yup_locale.string.noLeadingTrailingSpaces'),
    },
    number: {
      min(params) {
        return t('yup_locale.number.min', { min: params.min.toLocaleString() });
      },
      max(params) {
        return t('yup_locale.number.max', { max: params.max.toLocaleString() });
      },
      lessThan(params) {
        return t('yup_locale.number.lessThan', { less: params.less.toLocaleString() });
      },
      moreThan(params) {
        return t('yup_locale.number.moreThan', { more: params.more.toLocaleString() });
      },
      positive: t('yup_locale.number.positive'),
      negative: t('yup_locale.number.negative'),
      integer: t('yup_locale.number.integer'),
    },
    date: {
      min(params) {
        return t('yup_locale.date.min', { min: moment(params.min).format(t('common.date_format')) });
      },
      max(params) {
        return t('yup_locale.date.max', { max: moment(params.max).format(t('common.date_format')) });
      },
    },
  });
};

declare module 'yup' {
  interface StringSchema {
    ethAddress(message?: string): this;
    katakana(message?: string): this;
    katakanaAddress(message?: string): this;
    phoneNumber(message?: string): this;
    noLeadingTrailingSpaces(message?: string): this;
    numberString(message?: string): this;
  }

  interface CustomLocaleObject extends yup.LocaleObject {
    string: NonNullable<yup.LocaleObject['string']> & StringLocale;
  }

  function setLocale(custom: CustomLocaleObject): void;

  interface StringLocale {
    ethAddress?: string;
    katakana?: string;
    phoneNumber?: string;
    noLeadingTrailingSpaces?: string;
    numberString?: string;
  }
}
