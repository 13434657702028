import React, { useCallback, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import DialogCloseButton from '../dialog-close-button';

import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({}));

interface Props extends DialogProps {
  onClose: () => void;
  onYes: () => Promise<void>;
  title?: string;
  buttonYesLabel?: string;
  buttonNoLabel?: string;
  classes?: StyledComponentProps<typeof useStyles>['classes'] & DialogProps['classes'];
}

const ConfirmationDialog: React.FC<Props> = (props) => {
  const { open, onClose, onYes, title, buttonNoLabel, buttonYesLabel, ...others } = props;

  return (
    <Dialog open={open} onClose={onClose} {...others} maxWidth="sm" fullWidth>
      <Content {...props} />
    </Dialog>
  );
};

const Content: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { onClose, title, onYes, buttonNoLabel, buttonYesLabel } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onClickYes = useCallback(async () => {
    setLoading(true);
    try {
      await onYes();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
    setLoading(false);
  }, [enqueueSnackbar, onYes]);

  return (
    <>
      <DialogCloseButton onClick={onClose} data-testid="close-button">
        <CloseIcon />
      </DialogCloseButton>
      <DialogTitle>
        <Typography variant="h5" color="primary" component="p">
          {title || t('confirmation_dialog.content')}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose}>
          {buttonNoLabel || t('common.no')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={onClickYes}
          disabled={loading}
          endIcon={loading && <CircularProgress size={20} color="inherit" />}
        >
          {buttonYesLabel || t('common.yes')}
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmationDialog;
