import { Connector, ConnectorData } from "./connectors/base"
import { Provider } from "./types"

export type Data<TProvider extends Provider> = ConnectorData<TProvider>

export type State<
  TProvider extends Provider,
> = {
  chains?: Connector['chains']
  connector?: Connector
  connectors: Connector[]
  data?: Data<TProvider>
  error?: Error
  status: 'connected' | 'connecting' | 'reconnecting' | 'disconnected'
  }

export class Store<TProvider extends Provider> {
  private state: State<TProvider>
  private listeners: Set<(s: any) => void> = new Set()

  constructor({ initialState }: { initialState: State<TProvider> }) {
    this.state = initialState
  }
 
  getState() {
    return this.state
  }

  setState(updater: Partial<State<TProvider>>) {
    this.state = {
      ...this.state,
      ...updater,
      data: { ...this.state.data, ...updater.data }
    }
    this.listeners.forEach((listener) => listener(this.state))
  }

  subscribe(listener: (state: State<TProvider>) => void) {
    this.listeners.add(listener)
    // Unsubscribe
    return () => this.listeners.delete(listener)
  }

  clearState() {
    this.state = {
      chains: undefined,
      connectors: this.state.connectors,
      connector: undefined,
      data: undefined,
      error: undefined,
      status: "disconnected"
    }
    this.listeners.forEach((listener) => listener(this.state))
  }
}