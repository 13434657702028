import WalletConnectLegacyProvider from '@walletconnect/legacy-provider'

import {
  AddChainError,
  ChainNotConfiguredError,
  ProviderRpcError,
  SwitchChainError,
  UserRejectedRequestError
} from '../errors'
import { Chain } from '../types'
import { normalizeChainId } from '../utils/chain'
import { Connector } from './base'
import { BrowserProvider, Eip1193Provider, getAddress, toQuantity } from 'ethers'

/**
 * Wallets that support chain switching through WalletConnectLegacy
 * - imToken (token.im)
 * - MetaMask (metamask.io)
 * - Rainbow (rainbow.me)
 */
const switchChainAllowedRegex = /(imtoken|metamask|rainbow)/i

type WalletConnectLegacyOptions = ConstructorParameters<typeof WalletConnectLegacyProvider>[0]

// WARNING: WalletConnect v1 has been sunset.
// You must migrate to the WalletConnect v2 Connector before June 28, after which,
// this Connector will be removed.
export class WalletConnectLegacyConnector extends Connector<WalletConnectLegacyProvider, WalletConnectLegacyOptions> {
  readonly name = 'WalletConnectLegacy'
  readonly ready = true

  private provider?: WalletConnectLegacyProvider

  constructor(config: { chains?: Chain[]; options: WalletConnectLegacyOptions }) {
    super(config)
  }

  async connect() {
    try {
      const provider = await this.getProvider(true)
      provider.on('accountsChanged', this.onAccountsChanged)
      provider.on('chainChanged', this.onChainChanged)
      provider.on('disconnect', this.onDisconnect)
      this.emit('connect')

      const accounts = await provider.enable()
      const account = getAddress(accounts[0])
      const id = await this.getChainId()
      const unsupported = this.isChainUnsupported(id)

      // Not all WalletConnectLegacy options support programmatic chain switching
      // Only enable for wallet options that do
      const walletName = provider.connector?.peerMeta?.name ?? ''
      if (switchChainAllowedRegex.test(walletName)) this.switchChain = this.switchChain_

      return {
        account,
        chain: { id, unsupported },
        provider: new BrowserProvider(<Eip1193Provider>provider)
      }
    } catch (error) {
      if (/user closed modal/i.test((<ProviderRpcError>error).message)) throw new UserRejectedRequestError(error)
      throw error
    }
  }

  async disconnect() {
    const provider = await this.getProvider()
    await provider.disconnect()

    provider.removeListener('accountsChanged', this.onAccountsChanged)
    provider.removeListener('chainChanged', this.onChainChanged)
    provider.removeListener('disconnect', this.onDisconnect)
  }

  async getAccount() {
    const provider = await this.getProvider()
    const accounts = provider.accounts
    // return checksum address
    return getAddress(accounts[0])
  }

  async getChainId() {
    const provider = await this.getProvider()
    const chainId = normalizeChainId(provider.chainId)
    return chainId
  }

  async getProvider(create?: boolean) {
    if (!this.provider || create) {
      const chainId = this.options?.chainId || this.chains[0].id
      const rpc = !this.options?.infuraId
        ? this.chains.reduce(
            (rpc, chain) => ({
              ...rpc,
              [chain.id]: chain.rpcUrls?.[0]
            }),
            {}
          )
        : {}

      this.provider = new WalletConnectLegacyProvider({
        ...this.options,
        chainId,
        rpc: { ...rpc, ...this.options?.rpc }
      })
    }
    return this.provider
  }

  async getSigner() {
    const [provider, account] = await Promise.all([this.getProvider(), this.getAccount()])
    return new BrowserProvider(<Eip1193Provider>provider).getSigner(account)
  }

  async isAuthorized() {
    try {
      const account = await this.getAccount()
      return !!account
    } catch {
      return false
    }
  }

  private async switchChain_(chainId: number) {
    const provider = await this.getProvider()
    const id = toQuantity(chainId)

    const chain = this.chains.find((x) => x.id === chainId)
    if (!chain) throw new ChainNotConfiguredError()
    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: id }]
      })

      return chain
    } catch (error) {
      const message = typeof error === 'string' ? error : (<ProviderRpcError>error)?.message
      if (/wallet_addEthereumChain/i.test(message)) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: id,
                chainName: chain.name,
                nativeCurrency: chain.nativeCurrency,
                rpcUrls: chain.rpcUrls,
                blockExplorerUrls: chain.blockExplorerUrls
              }
            ]
          })
          return chain
        } catch (addError) {
          if (/user rejected/i.test(addError.message)) {
            throw new UserRejectedRequestError(addError)
          }
          throw new AddChainError()
        }
      }
      if (/user rejected/i.test(message)) throw new UserRejectedRequestError(error)
      throw new SwitchChainError(error)
    }
  }

  protected onAccountsChanged = (accounts: string[]) => {
    if (accounts.length === 0) this.emit('disconnect')
    else this.emit('update', { account: getAddress(accounts[0]) })
  }

  protected onChainChanged = async (chainId: number | string) => {
    const id = normalizeChainId(chainId)
    const unsupported = this.isChainUnsupported(id)
    const provider = await this.getProvider(true)

    this.emit('update', {
      chain: { id, unsupported },
      provider: new BrowserProvider(<Eip1193Provider>provider) as any
    })
  }

  protected onDisconnect = () => {
    this.emit('disconnect')
  }
}
