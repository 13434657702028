import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import App from '~/App';
import { AppRouteEnum } from '~/enums/route.enum';
import AboutPage from '~/pages/about';
import ErrorPage from '~/pages/error';
import PaymentPage from '~/pages/payment';
import SignInPage from '~/pages/sign-in';

export const routerConfig = createBrowserRouter([
  {
    path: AppRouteEnum.ABOUT,
    element: <AboutPage />,
  },
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: AppRouteEnum.PAYMENT,
        element: <PaymentPage />,
      },
      {
        path: AppRouteEnum.SIGN_IN,
        element: <SignInPage />,
      },
      {
        path: '/',
        element: <ErrorPage code={404} />,
      },
      {
        path: '*',
        element: <ErrorPage code={404} />,
      },
    ],
  },
]);

const AppRouter = () => {
  return <RouterProvider router={routerConfig} />;
};

export default AppRouter;
