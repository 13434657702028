import { useCallback } from 'react'
import { ConnectorNotFoundError, ProviderRpcError, UserRejectedRequestError } from '../../core/errors'
import { useClient } from '../context'
import { BytesLike } from 'ethers'

export type SignMessageArgs = {
  /** Message to sign with wallet */
  message: BytesLike
}

type Signature = string
export type SignMessageResult = Signature

export function useSignMessage() {
  const client = useClient()

  const signMessage = useCallback(
    async (args: SignMessageArgs): Promise<SignMessageResult> => {
      try {
        const signer = await client?.connector?.getSigner()

        if (!signer) throw new ConnectorNotFoundError()
        return await signer.signMessage(args.message)
      } catch (error) {
        // metamask throw error code: ACTION_REJECTED
        if ((<ProviderRpcError>error).code === 4001 || error.code === 'ACTION_REJECTED')
          throw new UserRejectedRequestError(error)
        throw error
      }
    },
    [client]
  )

  return { signMessage }
}
