import { useEffect, useState } from 'react';

import { Web3AuthConnector, useAccount, useConnect } from '@gusdk/gu-wallet-connector';
import { Box } from '@mui/material';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Navigate, Link as RouterLink, useLocation, generatePath } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import WalletConnector from './wallet-connector';

import AuthLayout from '~/components/auth-layout';
import FirebaseAuthLocalized from '~/components/firebase-auth-localized';
import ScreenLoading from '~/components/screen-loading';
import { AppRouteEnum } from '~/enums/route.enum';
import { env } from '~/env';

const useStyles = makeStyles()((theme) => ({
  paper: {
    border: `1px solid ${theme.palette.divider}`,
    height: '100%',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  flexPaper: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  logo: {
    maxWidth: '95%',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  temporaryButton: {
    width: 'fit-content',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  flexButtomButton: {
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
}));

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const SignInPage: React.FC = () => {
  const { classes } = useStyles(undefined, { props: {} });
  const { t, i18n } = useTranslation();
  const { state } = useLocation();
  const { account, status } = useAccount();
  const method = useQuery().get('method');
  const { connectors, connect } = useConnect();
  const [emailConfirming, setEmailConfirming] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { paymentUuid, callbackUrl } = (state || {}) as { paymentUuid: string; callbackUrl: string };

  const firebaseLoginCallback: Partial<firebaseui.auth.Config> = {
    callbacks: {
      signInSuccessWithAuthResult: (authResult) => {
        try {
          const user = authResult.user as firebase.default.User;
          if (!user) {
            enqueueSnackbar('login.no_user', { variant: 'error' });
            return false;
          }
          if (!user.emailVerified) {
            user.sendEmailVerification();
            // This `setState` never be affected by mobile browser's redirect or popup(opening new tab) behavior
            // since we stay in the same screen if email login. this means we stay on current JavaScript context.
            setEmailConfirming(true);
            return false;
          }
          user.getIdToken().then(async (idToken: string) => {
            const w3aConnector = connectors.find((connector) => connector instanceof Web3AuthConnector);
            if (!w3aConnector) {
              return false;
            }
            await connect(w3aConnector as Web3AuthConnector, {
              loginProvider: 'jwt',
              extraLoginOptions: {
                id_token: idToken,
                verifierIdField: 'sub',
                domain: env.REACT_APP_W3A_VERIFIER_DOMAIN,
              },
            });
          });
        } catch (error: any) {
          enqueueSnackbar(error.message, { variant: 'error' });
        } finally {
          return false;
        }
      },
    },
  };

  useEffect(() => {
    document.title = t('common.sign_in');
  }, [t]);

  if (!paymentUuid) {
    return null;
  }

  if (status === 'connecting' || status === 'reconnecting') {
    return <ScreenLoading />;
  }

  if (status === 'connected') {
    return (
      <Navigate
        to={generatePath(AppRouteEnum.PAYMENT, { paymentUuid }) + `?callback=${encodeURIComponent(callbackUrl)}`}
      />
    );
  }

  return (
    <AuthLayout>
      {method === 'sns' ? (
        <Paper className={classes.paper} elevation={0}>
          <Box>
            <Typography variant="h5" fontWeight="bold" marginBottom={1}>
              {t('login.connect_with_sns')}
            </Typography>
            {emailConfirming ? (
              <Typography variant="body1" whiteSpace="pre-wrap" marginBottom={5}>
                {t('login.email_confirmation_warning')}
              </Typography>
            ) : (
              <>
                <Typography variant="body1" whiteSpace="pre-wrap" marginBottom={5}>
                  {t('login.connect_with_sns_description')}
                </Typography>
                <FirebaseAuthLocalized language={i18n.language} config={firebaseLoginCallback} />
              </>
            )}
          </Box>
          <Button
            component={RouterLink}
            to={generatePath(AppRouteEnum.PAYMENT, { paymentUuid })}
            fullWidth
            variant="text"
          >
            {t('common.use_another_login_method')}
          </Button>
        </Paper>
      ) : (
        <Paper className={`${classes.paper} ${classes.flexPaper}`} elevation={0}>
          <Box>
            <Typography variant="h5" fontWeight="bold" marginBottom={1}>
              {t('login.connect_to_wallet')}
            </Typography>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '65%' }}>
              <Typography variant="body1" whiteSpace="pre-wrap" marginBottom={5}>
                {t('login.connect_to_wallet_description')}
              </Typography>
              <Button
                component={RouterLink}
                to={
                  generatePath(AppRouteEnum.PAYMENT, { paymentUuid }) + `?callback=${encodeURIComponent(callbackUrl)}`
                }
                variant="text"
                className={classes.temporaryButton}
              >
                {t('common.cancel')}
              </Button>
            </Box>
          </Box>
          <Box>
            <Typography variant="h6" marginBottom={1}>
              {t('login.available_wallets')}
            </Typography>
            <WalletConnector />
          </Box>
          <Button
            component={RouterLink}
            to={generatePath(AppRouteEnum.PAYMENT, { paymentUuid }) + `?callback=${encodeURIComponent(callbackUrl)}`}
            fullWidth
            variant="text"
            className={classes.flexButtomButton}
          >
            {t('common.cancel')}
          </Button>
        </Paper>
      )}
    </AuthLayout>
  );
};

export default SignInPage;
