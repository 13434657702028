type EnvType = {
  REACT_APP_FIREBASE_PUBLIC_API_KEY: string;
  REACT_APP_FIREBASE_AUTH_DOMAIN: string;
  REACT_APP_FIREBASE_DATABASE_URL: string;
  REACT_APP_FIREBASE_PROJECT_ID: string;
  REACT_APP_FIREBASE_STORAGE_BUCKET: string;
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID: string;
  REACT_APP_FIREBASE_APP_ID: string;
  REACT_APP_API_CHECKOUT_SERVER: string;
  REACT_APP_NETWORK_RPC_URL: string;
  REACT_APP_BLOCK_EXPLORER_URL: string;
  REACT_APP_API_SUBQUERY_SERVER: string;
  REACT_APP_HELP_URL: string;
  REACT_APP_W3A_NETWORK: NonNullable<import('@web3auth/no-modal').Web3AuthNoModalOptions['web3AuthNetwork']>;
  REACT_APP_W3A_CHAIN: import('~/config/chainConfig').CHAIN_CONFIG_TYPE;
  REACT_APP_W3A_CLIENT_ID: string;
  REACT_APP_W3A_VERIFIER_NAME: string;
  REACT_APP_W3A_VERIFIER_DOMAIN: string;
  REACT_APP_WALLET_CONNECT_PROJECT_ID: string;
  REACT_APP_DISABLE_W3A?: number; // 1 || 0
};
export const env: EnvType = process.env.NODE_ENV === 'production' ? (window as any)['env'] : { ...process.env };
env.REACT_APP_DISABLE_W3A = Number(env.REACT_APP_DISABLE_W3A || '0');
