import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from 'tss-react/mui';

import { StyledComponentProps } from '../../types/material-ui';

const useStyles = makeStyles()((theme) => ({
  root: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  logo: {
    padding: theme.spacing(2),
    width: '100%',
    maxWidth: 150,
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {}

const ScreenLoading: React.FC<Props> = (props) => {
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });

  return (
    <div className={classes.root} data-testid="loading">
      <CircularProgress />
    </div>
  );
};

export default ScreenLoading;
