import React, { ReactElement, useCallback, useMemo, useState } from 'react'

import { makeStyles } from 'tss-react/mui'
import { StyledComponentProps } from '../../types'

import UnsupportedBrowserDialog from './UnsupportedBrowserDialog'
import WalletLockDialog from './WalletLockDialog'
import WalletNotInstallDialog from './WalletNotInstallDialog'

const useStyles = makeStyles()((theme) => ({}))

interface Props extends StyledComponentProps<typeof useStyles> {
  children: ReactElement
  disable?: boolean
}

const BrowserWalletRequired: React.FC<Props> = ({ children, disable }) => {
  const [openUnsupportedBrowserDialog, setOpenUnsupportedBrowserDialog] = useState(false)
  const [openWalletNotInstallDialog, setOpenWalletNotInstallDialog] = useState(false)
  const [openWalletLockDialog, setOpenWalletLockDialog] = useState(false)

  const onCloseUnsupportedBrowserDialog = useCallback(() => {
    setOpenUnsupportedBrowserDialog(false)
  }, [])

  const onCloseWalletLockDialog = useCallback(() => {
    setOpenWalletLockDialog(false)
  }, [])

  const onCloseWalletNotInstallDialog = useCallback(() => {
    setOpenWalletNotInstallDialog(false)
  }, [])

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      if (/Mobile/i.test(navigator.userAgent) && !window.ethereum) {
        setOpenUnsupportedBrowserDialog(true)
      } else if (!window.ethereum) {
        setOpenWalletNotInstallDialog(true)
      } else if (!window.ethereum.selectedAddress) {
        setOpenWalletLockDialog(true)
      } else {
        children.props?.onClick(event)
      }
    },
    [children.props]
  )

  const renderchildren = useMemo(() => {
    return React.cloneElement(children, { onClick })
  }, [children, onClick])

  if (disable) {
    return children
  }

  return (
    <>
      <UnsupportedBrowserDialog open={openUnsupportedBrowserDialog} onClose={onCloseUnsupportedBrowserDialog} />
      <WalletNotInstallDialog open={openWalletNotInstallDialog} onClose={onCloseWalletNotInstallDialog} />
      <WalletLockDialog
        open={openWalletLockDialog}
        onNext={children.props?.onClick}
        onClose={onCloseWalletLockDialog}
      />
      {renderchildren}
    </>
  )
}

export default BrowserWalletRequired
