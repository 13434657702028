interface Props {}

const AboutPage = (props: Props) => {
  return (
    <>
      <h1>About</h1>
      <p>Version : {process.env.REACT_APP_VERSION}</p>
    </>
  );
};

export default AboutPage;
