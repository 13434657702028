import { useEffect, useState } from 'react'
import { erc20ABI } from '../../core/constants/abis'
import { useClient } from '../context'
import { BigNumberish, Contract } from 'ethers'

interface ERC20Balance {
  value: BigNumberish
  decimals: number
  symbol: string
}

export function useERC20Balance({ walletAddress, token }: { walletAddress: string; token: string }) {
  const [balance, setBalance] = useState<undefined | ERC20Balance>(undefined)

  const client = useClient()
  const chainId = client.data?.chain?.id

  useEffect(() => {
    ;(async () => {
      try {
        const provider = client.data?.provider
        if (!provider) return

        const contract = new Contract(token, erc20ABI, provider)
        const [value, decimals, symbol] = await Promise.all([
          contract.balanceOf(walletAddress),
          contract.decimals(),
          contract.symbol()
        ])

        setBalance({
          value,
          decimals,
          symbol
        })
      } catch (error) {
        setBalance(undefined)
      }
    })()
  }, [walletAddress, chainId])

  return balance
}
