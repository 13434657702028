import WalletConnectProvider from '@walletconnect/ethereum-provider'
import { OPTIONAL_METHODS, OPTIONAL_EVENTS } from '@walletconnect/ethereum-provider'
import { EthereumProviderOptions } from '@walletconnect/ethereum-provider/dist/types/EthereumProvider'
import { normalizeNamespaces } from '@walletconnect/utils'

import { Chain } from '../types'
import { normalizeChainId } from '../utils/chain'
import { Connector } from './base'
import { ProviderRpcError, SwitchChainError, UserRejectedRequestError } from '../errors'
import { BrowserProvider, Eip1193Provider, getAddress, toQuantity } from 'ethers'

type WalletConnectOptions = {
  /**
   * WalletConnect Cloud Project ID.
   * @link https://cloud.walletconnect.com/sign-in.
   */
  projectId: EthereumProviderOptions['projectId']

  /**
   * Metadata for your app.
   * @link https://docs.walletconnect.com/2.0/javascript/providers/ethereum#initialization
   */
  metadata?: EthereumProviderOptions['metadata']

  /**
   * Whether or not to show the QR code modal.
   * @default true
   * @link https://docs.walletconnect.com/2.0/javascript/providers/ethereum#initialization
   */
  showQrModal?: EthereumProviderOptions['showQrModal']

  /**
   * Options of QR code modal.
   * @link https://docs.walletconnect.com/2.0/web/walletConnectModal/modal/options
   */
  qrModalOptions?: EthereumProviderOptions['qrModalOptions']
}

type ConnectConfig = {
  /** Target chain to connect to. */
  chainId?: number
  /** If provided, will attempt to connect to an existing pairing. */
  pairingTopic?: string
}

export class WalletConnectConnector extends Connector<WalletConnectProvider, WalletConnectOptions> {
  readonly name = 'WalletConnect'
  readonly ready = true

  private _provider?: WalletConnectProvider
  private _initProviderPromise?: Promise<void>
  private _storage?: Map<string, number[]>

  constructor(config: { chains?: Chain[]; options: WalletConnectOptions }) {
    super(config)
    this._storage = new Map()
    this._createProvider()
  }

  async connect({ chainId, pairingTopic }: ConnectConfig = {}) {
    try {
      let targetChainId = chainId
      if (!targetChainId) {
        targetChainId = this.chains[0]?.id
      }

      if (!targetChainId) throw new Error('No chains found on connector.')

      const provider = await this.getProvider()
      this._setupListeners()

      const isChainsStale = this._isChainsStale()

      if (provider.session && isChainsStale) {
        await provider.disconnect()
      }

      if (!provider.session || isChainsStale) {
        const optionalChainIs = this.chains
          .filter((chain) => chain.id !== targetChainId)
          .map((optionalChain) => optionalChain.id)

        this.emit('connect')

        await provider.connect({
          pairingTopic,
          chains: [targetChainId],
          optionalChains: optionalChainIs.length ? optionalChainIs : undefined
        })

        this._setRequestedChainsIds(this.chains.map(({ id }) => id))
      }

      // If session exists and chains are authorized, enable provider for required chain
      const accounts = await provider.enable()
      const account = getAddress(accounts[0]!)
      const id = await this.getChainId()
      const unsupported = this.isChainUnsupported(id)

      return {
        account,
        chain: { id, unsupported },
        provider: new BrowserProvider(<Eip1193Provider>provider)
      }
    } catch (error) {
      if (/user rejected/i.test((<ProviderRpcError>error).message)) throw new UserRejectedRequestError(error)
      throw error
    }
  }

  async disconnect() {
    const provider = await this.getProvider()
    try {
      await provider.disconnect()
    } catch (error) {
      if (!/No matching key/i.test((error as Error).message)) throw error
    } finally {
      this._removeListeners()
      this._setRequestedChainsIds([])
    }
  }

  async getAccount() {
    const { accounts } = await this.getProvider()
    return getAddress(accounts[0]!)
  }

  async getChainId() {
    const provider = await this.getProvider()
    const chainId = normalizeChainId(provider.chainId)
    return chainId
  }

  async getProvider() {
    if (!this._provider) await this._createProvider()
    return this._provider!
  }

  async getSigner() {
    const [provider, account] = await Promise.all([this.getProvider(), this.getAccount()])
    return new BrowserProvider(<Eip1193Provider>provider).getSigner(account)
  }

  async isAuthorized() {
    try {
      const [account, provider] = await Promise.all([this.getAccount(), this.getProvider()])
      const isChainsStale = this._isChainsStale()

      // If an account does not exist on the session, then the connector is unauthorized.
      if (!account) return false

      // If the chains are stale on the session, then the connector is unauthorized.
      if (isChainsStale && provider.session) {
        try {
          await provider.disconnect()
        } catch {} // eslint-disable-line no-empty
        return false
      }

      return true
    } catch {
      return false
    }
  }

  async switchChain(chainId: number) {
    const chain = this.chains.find((chain) => chain.id === chainId)
    if (!chain) throw new SwitchChainError(new Error('chain not found on connector.'))

    try {
      const provider = await this.getProvider()
      const namespaceChains = this._getNamespaceChainsIds()
      const namespaceMethods = this._getNamespaceMethods()
      const isChainApproved = namespaceChains.includes(chainId)

      if (!isChainApproved && namespaceMethods.includes('wallet_addEthereumChain')) {
        await provider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: toQuantity(chainId),
              chainName: chain.name,
              nativeCurrency: chain.nativeCurrency,
              rpcUrls: chain.rpcUrls,
              blockExplorerUrls: chain.blockExplorerUrls
            }
          ]
        })
        const requestedChains = this._getRequestedChainsIds()
        requestedChains.push(chainId)
        this._setRequestedChainsIds(requestedChains)
      }
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: toQuantity(chainId) }]
      })

      return chain
    } catch (error) {
      console.log(error)
      const message = typeof error === 'string' ? error : (error as ProviderRpcError)?.message
      if (/user rejected request/i.test(message)) {
        throw new UserRejectedRequestError(error as Error)
      }
      throw new SwitchChainError(error as Error)
    }
  }

  private async _createProvider() {
    if (!this._initProviderPromise && typeof window !== 'undefined') {
      this._initProviderPromise = this._initProvider()
    }
    return this._initProviderPromise
  }

  private async _initProvider() {
    const [defaultChainId, ...optionalChainIds] = this.chains.map((chain) => chain.id)

    if (defaultChainId) {
      const { projectId, showQrModal = true, qrModalOptions, metadata } = this.options

      this._provider = await WalletConnectProvider.init({
        showQrModal,
        qrModalOptions,
        projectId,
        optionalMethods: OPTIONAL_METHODS,
        optionalEvents: OPTIONAL_EVENTS,
        chains: [defaultChainId],
        optionalChains: optionalChainIds.length ? optionalChainIds : undefined,
        rpcMap: Object.fromEntries(this.chains.map((chain) => [chain.id, chain.rpcUrls?.[0]])),
        metadata
      })
    }
  }

  private _setupListeners() {
    if (!this._provider) return
    this._removeListeners()
    this._provider.on('accountsChanged', this.onAccountsChanged)
    this._provider.on('chainChanged', this.onChainChanged)
    this._provider.on('disconnect', this.onDisconnect)
    this._provider.on('session_delete', this.onDisconnect)
    this._provider.on('connect', this.onConnect)
  }

  private _removeListeners() {
    if (!this._provider) return
    this._provider.removeListener('accountsChanged', this.onAccountsChanged)
    this._provider.removeListener('chainChanged', this.onChainChanged)
    this._provider.removeListener('disconnect', this.onDisconnect)
    this._provider.removeListener('session_delete', this.onDisconnect)
    this._provider.removeListener('connect', this.onConnect)
  }

  private _getNamespaceChainsIds() {
    if (!this._provider) return []
    const namespaces = this._provider.session?.namespaces
    if (!namespaces) return []

    const normalizedNamespaces = normalizeNamespaces(namespaces)
    const chainIds = normalizedNamespaces['eip155']?.chains?.map((chain) => parseInt(chain.split(':')[1] || ''))

    return chainIds ?? []
  }

  private _setRequestedChainsIds(chains: number[]) {
    this._storage?.set('requestedChains', chains)
  }

  private _getRequestedChainsIds(): number[] {
    return this._storage?.get('requestedChains') ?? []
  }

  private _getNamespaceMethods() {
    if (!this._provider) return []
    const namespaces = this._provider.session?.namespaces
    if (!namespaces) return []

    const normalizedNamespaces = normalizeNamespaces(namespaces)
    const methods = normalizedNamespaces['eip155']?.methods

    return methods ?? []
  }

  /**
   * Checks if the target chains match the chains that were
   * initially requested by the connector for the WalletConnect session.
   * If there is a mismatch, this means that the chains on the connector
   * are considered stale, and need to be revalidated at a later point (via
   * connection).
   *
   * There may be a scenario where a dapp adds a chain to the
   * connector later on, however, this chain will not have been approved or rejected
   * by the wallet. In this case, the chain is considered stale.
   *
   * There are exceptions however:
   * -  If the wallet supports dynamic chain addition via `eth_addEthereumChain`,
   *    then the chain is not considered stale.
   * -  If the `isNewChainsStale` flag is falsy on the connector, then the chain is
   *    not considered stale.
   *
   * For the above cases, chain validation occurs dynamically when the user
   * attempts to switch chain.
   *
   * Also check that dapp supports at least 1 chain from previously approved session.
   */
  private _isChainsStale() {
    const namespaceMethods = this._getNamespaceMethods()
    if (namespaceMethods.includes('wallet_addEthereumChain')) return false

    const requestedChains = this._getRequestedChainsIds()
    const connectorChains = this.chains.map(({ id }) => id)
    const namespaceChains = this._getNamespaceChainsIds()

    if (namespaceChains.length && !namespaceChains.some((id) => connectorChains.includes(id))) return false

    return !connectorChains.every((id) => requestedChains.includes(id))
  }

  protected onAccountsChanged = (accounts: string[]) => {
    if (accounts.length === 0) this.emit('disconnect')
    else this.emit('update', { account: getAddress(accounts[0]!) })
  }

  protected onChainChanged = async (chainId: number | string) => {
    const id = normalizeChainId(chainId)
    const unsupported = this.isChainUnsupported(id)
    const provider = await this.getProvider()

    this.emit('update', {
      chain: { id, unsupported },
      provider: new BrowserProvider(<Eip1193Provider>provider) as any
    })
  }

  protected onDisconnect = () => {
    this._setRequestedChainsIds([])
    this.emit('disconnect')
  }

  protected onConnect = () => {
    this.emit('connect', {})
  }
}
