import { useEffect, useState } from 'react'
import { useClient } from '../context'
import { BigNumberish } from 'ethers'

export function useEthBalance({ walletAddress }: { walletAddress: string }) {
  const [balance, setBalance] = useState<BigNumberish>(BigInt(0))

  const client = useClient()
  const chainId = client.data?.chain?.id

  useEffect(() => {
    ;(async () => {
      try {
        const provider = client.data?.provider
        if (!provider) {
          throw new Error('Provider not found')
        }

        const balance = await provider.getBalance(walletAddress)
        setBalance(balance)
      } catch (error) {
        setBalance(BigInt(0))
      }
    })()
  }, [walletAddress, chainId])

  return balance
}
