import { default as EventEmitter } from 'eventemitter3'

import { Chain } from '../types'
import { defaultChains } from '../constants/chains'
import { Signer } from 'ethers'

export type ConnectorData<Provider = any> = {
  account?: string
  chain?: { id: number; unsupported: boolean }
  provider?: Provider
}

export interface ConnectorEvents<Provider = any> {
  update(data: ConnectorData<Provider>): void
  connect(options?: Record<string, string>): void
  disconnect(): void
  error(error: Error): void
}

export abstract class Connector<Provider = any, Options = any, ConnectOptions = any> extends EventEmitter<
  ConnectorEvents<Provider>
> {
  abstract readonly name: string
  readonly chains: Chain[]
  readonly options: Options
  abstract readonly ready: boolean

  constructor({ chains = defaultChains, options }: { chains?: Chain[]; options?: Options }) {
    super()
    this.chains = chains
    this.options = options as any
  }

  abstract connect(options?: ConnectOptions): Promise<any>
  abstract disconnect(): Promise<void>
  abstract getAccount(): Promise<string>
  abstract getChainId(): Promise<number>
  abstract getProvider(): Promise<Provider>
  abstract getSigner(): Promise<Signer>
  /**
   * @dev Checking connector is authorized for website or not?
   */
  abstract isAuthorized(): Promise<boolean>
  switchChain?(chainId: number): Promise<Chain>
  watchAsset?(asset: { address: string; image?: string; symbol: string }): Promise<boolean>

  protected abstract onAccountsChanged(accounts: string[]): void
  protected abstract onChainChanged(chain: number | string): void
  protected abstract onDisconnect(error: Error): void

  protected isChainUnsupported(chainId: number): boolean {
    return !this.chains.some((x) => x.id === chainId)
  }
}
