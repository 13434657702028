import { TFunction } from 'react-i18next';

import { PaymentStateEnum } from '~/graphql/checkout/types';
import { ErrorPageType } from '~/pages/error';

export const getErrorPageMessageI18n = (t: TFunction, type?: ErrorPageType) => {
  switch (type) {
    case ErrorPageType.notFound:
      return t('error_page.not_found');
    default:
      return '-';
  }
};

export const getPaymentStateEnumI18n = (t: TFunction, type?: PaymentStateEnum) => {
  switch (type) {
    case PaymentStateEnum.Confirming:
      return t('payment_state_enum.confirming');
    case PaymentStateEnum.Done:
      return t('payment_state_enum.done');
    case PaymentStateEnum.Expired:
      return t('payment_state_enum.expired');
    case PaymentStateEnum.Waiting:
      return t('payment_state_enum.waiting');
    case PaymentStateEnum.Failure:
      return t('payment_state_enum.failure');
    default:
      return '-';
  }
};
