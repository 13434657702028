import React, { useCallback, useState } from 'react';

import {
  InjectedConnector,
  useConnect,
  WalletConnectConnector,
  BrowserWalletRequired,
  Web3AuthConnector,
} from '@gusdk/gu-wallet-connector';
import { Connector } from '@gusdk/gu-wallet-connector/lib/core/connectors/base';
import Avatar from '@mui/material/Avatar';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { alpha } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import InjectedWalletIcon from '~/icons/browser-wallet.svg';
import MetamaskIcon from '~/icons/metamask.svg';
import WalletConnectIcon from '~/icons/wallet-connect.svg';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  wrapLoading: {
    margin: 'auto',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: alpha(theme.palette.background.default, 0.5),
  },
  methodListItem: {
    minHeight: 88,
    minWidth: 286,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 16,
    padding: theme.spacing(3),
    gap: theme.spacing(2),
    backgroundColor: '#f5f6fc',
    [theme.breakpoints.down('sm')]: {
      minWidth: 240,
    },
  },
  walletLogo: {
    height: 'auto',
    width: 40,
    padding: theme.spacing(0.5),
    [theme.breakpoints.down('sm')]: {
      width: 40,
    },
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {
  onNext?: () => void;
}

const WalletConnector: React.FC<Props> = (props) => {
  const { onNext } = props;
  const { classes } = useStyles(undefined, {
    props: { classes: props.classes },
  });
  const { t } = useTranslation();
  const { connectors, connect, status } = useConnect();
  const { enqueueSnackbar } = useSnackbar();

  const filteredConnectors = connectors.filter((connector) => {
    return !(connector instanceof Web3AuthConnector);
  });

  const onConnect = useCallback(
    async (connector: Connector<any, any>) => {
      try {
        await connect(connector);
        onNext?.();
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
    [connect, enqueueSnackbar, onNext]
  );

  const getConfig = useCallback(
    (connector: Connector<any, any>, index: number) => {
      if (connector instanceof InjectedConnector) {
        return {
          logo: InjectedWalletIcon,
          name: t('login.browser_wallet'),
        };
      }
      if (connector instanceof WalletConnectConnector) {
        return {
          logo: WalletConnectIcon,
          name: t('login.walletconnect'),
        };
      }
    },
    [t]
  );

  return (
    <List sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      {filteredConnectors.map((connector, index) => (
        <BrowserWalletRequired key={index} disable={!(connector instanceof InjectedConnector)}>
          <ListItemButton className={classes.methodListItem} onClick={() => onConnect(connector)}>
            <ListItemAvatar sx={{ minWidth: 0 }}>
              <Avatar
                className={classes.walletLogo}
                variant="rounded"
                imgProps={{ sx: { objectFit: 'contain' } }}
                src={getConfig(connector, index)?.logo}
              />
            </ListItemAvatar>
            <ListItemText primaryTypographyProps={{ variant: 'h6' }} primary={getConfig(connector, index)?.name} />
          </ListItemButton>
        </BrowserWalletRequired>
      ))}
      {status === 'connecting' && (
        <div className={classes.wrapLoading}>
          <CircularProgress />
        </div>
      )}
    </List>
  );
};

export default WalletConnector;
