import { EventEmitter } from 'events'

import Torus, { TorusCtorArgs, TorusInpageProvider, TorusLoginParams, TorusParams } from '@toruslabs/torus-embed'

import { Connector } from './base'
import { Chain } from '../types'
import { normalizeChainId } from '../utils/chain'
import { BrowserProvider, Eip1193Provider, getAddress } from 'ethers'

EventEmitter.defaultMaxListeners = 50 // Fix: Torus error: Possible EventEmitter memory leak detected. By default EventEmitters will print a warning if more than 10 listeners are added for a particular event.

export interface TorusConnectorOptions {
  constructorArgs?: TorusCtorArgs
  loginArgs?: TorusLoginParams
  initArgs?: TorusParams
}

export class TorusConnector extends Connector<TorusInpageProvider, TorusConnectorOptions> {
  readonly name = 'Torus'
  readonly ready = true

  private _constructorArgs?: TorusCtorArgs
  private _loginArgs?: TorusLoginParams
  private _initArgs?: TorusParams

  private _torus?: Torus

  private pendingGetProvider: Promise<void>

  constructor(config: { chains?: Chain[]; options: TorusConnectorOptions }) {
    super(config)

    this._constructorArgs = this.options.constructorArgs
    this._loginArgs = this.options.loginArgs
    this._initArgs = {
      showTorusButton: false,
      ...this.options.initArgs
    }
  }

  async connect() {
    try {
      const provider = await this.getProvider()
      if (this._initArgs.showTorusButton === false) {
        this._torus.showTorusButton()
      }

      provider.on('accountsChanged', this.onAccountsChanged as any)
      provider.on('chainChanged', this.onChainChanged as any)
      provider.on('disconnect', this.onDisconnect)

      this.emit('connect')

      const account = await this.getAccount()
      const id = await this.getChainId()
      const unsupported = this.isChainUnsupported(id)

      return {
        account,
        chain: { id, unsupported },
        provider: new BrowserProvider(<Eip1193Provider>provider)
      }
    } catch (error) {
      throw error
    }
  }

  async disconnect() {
    const provider = await this.getProvider()
    provider.removeListener('accountsChanged', this.onAccountsChanged)
    provider.removeListener('chainChanged', this.onChainChanged)
    provider.removeListener('disconnect', this.onDisconnect)

    await this._torus?.cleanUp()
    this._torus = undefined
  }

  async getAccount() {
    const provider = await this.getProvider()
    const accounts = await provider.request<string[]>({
      method: 'eth_accounts'
    })
    return getAddress(accounts?.[0])
  }

  async getChainId() {
    const provider = await this.getProvider()
    const chainId = normalizeChainId(provider.chainId)
    return chainId
  }

  async getProvider() {
    if (!this._torus) {
      this._torus = new Torus(this._constructorArgs)
      // This medthod can call many times eventhough previous request haven't finish yet. We should avoid to call login again
      this.pendingGetProvider = new Promise(async (resolve, reject) => {
        try {
          await this._torus.init(this._initArgs)
          await this._torus.login(this._loginArgs)
          resolve()
        } catch (e) {
          reject(e)
        }
      })
    }
    await this.pendingGetProvider
    return this._torus.provider
  }

  async getSigner() {
    const [provider, account] = await Promise.all([this.getProvider(), this.getAccount()])
    return new BrowserProvider(<Eip1193Provider>provider).getSigner(account)
  }

  async isAuthorized() {
    try {
      const account = await this.getAccount()
      return !!account
    } catch {
      return false
    }
  }

  protected onAccountsChanged = (accounts: string[]) => {
    if (accounts.length === 0) {
      this.emit('disconnect')
    } else {
      this.emit('update', { account: getAddress(accounts[0]) })
    }
  }

  protected onChainChanged = async (chainId: number | string) => {
    const id = normalizeChainId(chainId)
    const unsupported = this.isChainUnsupported(id)
    const provider = await this.getProvider()
    this.emit('update', {
      chain: { id, unsupported },
      provider: new BrowserProvider(<Eip1193Provider>provider) as any
    })
  }

  protected onDisconnect = () => {
    this.emit('disconnect')
  }
}
