import { useCallback } from "react"
import { SwitchChainNotSupportedError } from "../../core/errors"
import { useClient } from "../context"

export function useSwitchNetwork() {
  const client = useClient()
  const switchNetwork = useCallback(async (chainId: number) => {
    if (!client.connector?.switchChain) throw new SwitchChainNotSupportedError()

    const chain = await client.connector.switchChain(chainId)
    return chain
  }, [client])

  return { switchNetwork }
}

