import { Chain } from '../types'
import { normalizeChainId } from '../utils/chain'
import {
  AddChainError,
  ChainNotConfiguredError,
  ConnectorNotFoundError,
  ProviderRpcError,
  ResourceUnavailableError,
  RpcError,
  SwitchChainError,
  UserRejectedRequestError
} from '../errors'

import { Connector } from './base'
import { BrowserProvider, Eip1193Provider, getAddress, toQuantity } from 'ethers'

export class InjectedConnector extends Connector<Window['ethereum']> {
  readonly name: string
  readonly ready = typeof window != 'undefined' && !!window.ethereum

  private provider?: Window['ethereum']

  constructor({
    chains
  }: {
    chains?: Chain[]
  } = {}) {
    super({ chains })
    this.name = 'Injected'
  }

  async connect() {
    try {
      const provider = await this.getProvider()
      if (!provider) throw new ConnectorNotFoundError()

      if (provider.on) {
        provider.on('accountsChanged', this.onAccountsChanged)
        provider.on('chainChanged', this.onChainChanged)
        provider.on('disconnect', this.onDisconnect)
      }

      this.emit('connect')
      const account = await this.getAccount()
      const id = await this.getChainId()
      const unsupported = this.isChainUnsupported(id)

      return { account, chain: { id, unsupported }, provider: new BrowserProvider(provider) }
    } catch (error) {
      if (this.isUserRejectedRequestError(error)) throw new UserRejectedRequestError(error)
      if ((<RpcError>error).code === -32002) throw new ResourceUnavailableError(error)
      throw error
    }
  }

  async disconnect() {
    const provider = await this.getProvider()
    if (!provider?.removeListener) return

    provider.removeListener('accountsChanged', this.onAccountsChanged)
    provider.removeListener('chainChanged', this.onChainChanged)
    provider.removeListener('disconnect', this.onDisconnect)
  }

  async getAccount() {
    const provider = await this.getProvider()
    if (!provider) throw new ConnectorNotFoundError()
    const accounts = await provider.request({
      method: 'eth_requestAccounts'
    })
    return getAddress(accounts[0])
  }

  async getChainId() {
    const provider = await this.getProvider()
    if (!provider) throw new ConnectorNotFoundError()
    return await provider.request({ method: 'eth_chainId' }).then(normalizeChainId)
  }

  async getProvider() {
    if (typeof window !== 'undefined' && !!window.ethereum) this.provider = window.ethereum
    return this.provider
  }

  async getSigner() {
    const [provider, account] = await Promise.all([this.getProvider(), this.getAccount()])
    return new BrowserProvider(<Eip1193Provider>provider).getSigner(account)
  }

  async isAuthorized() {
    try {
      const provider = await this.getProvider()
      if (!provider) throw new ConnectorNotFoundError()
      const accounts = await provider.request({
        method: 'eth_accounts'
      })
      const account = accounts[0]
      return !!account
    } catch {
      return false
    }
  }

  async switchChain(chainId: number) {
    const provider = await this.getProvider()
    if (!provider) throw new ConnectorNotFoundError()
    const id = toQuantity(chainId)

    try {
      await provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: id }]
      })
    } catch (error) {
      const chain = this.chains.find((x) => x.id === chainId)
      if (!chain) throw new ChainNotConfiguredError()

      if (
        (<ProviderRpcError>error).code === 4902 ||
        // Unwrapping for MetaMask Mobile
        // https://github.com/MetaMask/metamask-mobile/issues/2944#issuecomment-976988719
        (error as ProviderRpcError<{ originalError?: { code: number } }>)?.data?.originalError?.code === 4902
      ) {
        try {
          await provider.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId: id,
                chainName: chain.name,
                nativeCurrency: chain.nativeCurrency,
                rpcUrls: chain.rpcUrls,
                blockExplorerUrls: chain.blockExplorerUrls
              }
            ]
          })
          return chain
        } catch (addError) {
          if (this.isUserRejectedRequestError(addError)) throw new UserRejectedRequestError(error)
          throw new AddChainError()
        }
      }

      if (this.isUserRejectedRequestError(error)) throw new UserRejectedRequestError(error)
      throw new SwitchChainError(error)
    }
  }

  async watchAsset({
    address,
    decimals = 18,
    image,
    symbol
  }: {
    address: string
    decimals?: number
    image?: string
    symbol: string
  }) {
    const provider = await this.getProvider()
    if (!provider) throw new ConnectorNotFoundError()
    return await provider.request({
      method: 'wallet_watchAsset',
      params: {
        type: 'ERC20',
        options: {
          address,
          decimals,
          image,
          symbol
        }
      }
    })
  }

  protected onAccountsChanged = (accounts: string[]) => {
    if (accounts.length === 0) this.emit('disconnect')
    else this.emit('update', { account: getAddress(accounts[0]) })
  }

  protected onChainChanged = async (chainId: number | string) => {
    const id = normalizeChainId(chainId)
    const unsupported = this.isChainUnsupported(id)
    const provider = await this.getProvider()
    this.emit('update', {
      chain: { id, unsupported },
      provider: new BrowserProvider(provider)
    })
  }

  protected onDisconnect = () => {
    this.emit('disconnect')
  }

  private isUserRejectedRequestError(error: unknown) {
    return (<ProviderRpcError>error).code === 4001
  }
}
