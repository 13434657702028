import React, { useEffect, useState } from 'react'

import CloseIcon from '@mui/icons-material/Close'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { useTranslation } from 'react-i18next'
import { makeStyles } from 'tss-react/mui'
import Stack from '@mui/material/Stack'
import IconButton from '@mui/material/IconButton'

import { StyledComponentProps } from '../../../types'

const useStyles = makeStyles()((theme) => ({}))
interface Props extends StyledComponentProps<typeof useStyles> {
  open: boolean
  onClose: () => void
}

const WalletNotInstallDialog: React.FC<Props> = (props) => {
  const { open, onClose } = props
  const { classes } = useStyles(undefined, { props: { classes: props.classes } })
  const { t } = useTranslation()
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    setIsMobile(/Mobile/i.test(navigator.userAgent))
  }, [])

  return (
    <Dialog open={open} onClose={onClose} data-testid="wallet-not-install-dialog">
      <DialogTitle>
        <Stack direction="row" alignItems={'flex-start'} width={'100%'} justifyContent={'space-between'}>
          <Typography variant="h6" color="primary" component="p" fontWeight="bold">
            {t('wallet_not_install_dialog.title')}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Typography variant="body1" color="textSecondary" component="p">
          {t('wallet_not_install_dialog.description')}
        </Typography>
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Typography variant="body1">{t('wallet_not_install_dialog.content')}</Typography>
      </DialogContent>
      <DialogActions>
        {!isMobile && (
          <Button
            color="primary"
            variant="contained"
            component="a"
            target="_blank"
            href="https://chrome.google.com/webstore/detail/gu-wallet/nfinomegcaccbhchhgflladpfbajihdf"
          >
            {t('wallet_not_install_dialog.get_wallet')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default WalletNotInstallDialog
